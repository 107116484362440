let previousResult = null;
let previousPatientNotes = null;

export function getPatientNotesSelector({notesReducer}) {
    if ( notesReducer && 
        notesReducer.patientNotes
    )
    return notesReducer.patientNotes;
    else return [];
}

export function getPatientBookmarkedNotesSelector({ notesReducer }) {
  if (notesReducer && Array.isArray(notesReducer.patientNotes)) {
    // Check if patientNotes have changed
    if (notesReducer.patientNotes === previousPatientNotes) {
      return previousResult; // Return cached result if notes haven't changed
    }

    // Filter bookmarked notes
    const filteredNotes = notesReducer.patientNotes.filter(
      (note) => note.isBookmarked === "true"
    );

    // Update cached values
    previousPatientNotes = notesReducer.patientNotes;
    previousResult = filteredNotes;

    return filteredNotes;
  }

  // Reset cache and return empty array if invalid
  previousPatientNotes = null;
  previousResult = [];
  return [];
}

export function postPatientNotesSelector({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.patientNotes
    )
    return notesReducer.patientNotes;
    else return [];
}

export function getNotesTagsSelector({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.notesTags
    )
    return notesReducer.notesTags;
    else return [];
}

export function getSelectedNotesTimestamp({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.selectedTimestamp
    )
    return notesReducer.selectedTimestamp;
    else return "";
}

export function getSelectedNotesVitalsAccOpen({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.showVitalsAccordian
    )
    return notesReducer.showVitalsAccordian;
    else return false;
}

export function postPatientNotesSuccessorSelector({notesReducer}) {
    if (
        notesReducer
    )
    return notesReducer.patientNoteAdded;
    else return false;
}

export function getOpenAddNotesPanelSelector({notesReducer}) {
    if (
        notesReducer &&
        notesReducer.openAddNotesPanel
    )
    return notesReducer.openAddNotesPanel;
    else return false;
}

export function selectedRecordIdForNotesSelector({notesReducer}) {
    if (
        notesReducer &&
        notesReducer.selectedRecordIdForNotes
    )
    return notesReducer.selectedRecordIdForNotes;
    else return '';
}