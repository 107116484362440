import React, { useState, useEffect } from "react";
import styles from "./TableHeader.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import TableBody from "../TableBody/TableBody";
import { useSelector } from "react-redux";
import { getIsGetPatientsAPIFailure } from "../../../../../Reducer/selectors/DashboardSelector";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import SearchBar from "../../../../../Utils/SearchBar";

export default function TableHeader(props) {
  const [patientListSorting, setPatientListSorting] = useState([]);
  const [sortDirection, setSortDirection] = useState(false);
  const [searched, setSearched] = useState("");
  const options = [
    { value: "active", label: "Active", color: "#EEFFF7" },
    { value: "calibrate", label: "Calibrate", color: "#FFEDED" },
    { value: "registered", label: "Registered", color: "#FDEAFF" },
    { value: "inactive", label: "Inactive", color: "#EEEFF2" },
  ];  

  const defaultOptions = [options[0], options[1], options[2]];

  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);

  const [firstLoad, setFirstLoad] = useState(true);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    filterFunction(searched, selected);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "12px",
      borderColor: "#ccc",
      display: "flex",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "max-content",
      fontFamily: "GTWalsheimProRegular",
    }),
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? data.color
        : isFocused
        ? "#f0f0f0"
        : "white",
      color: "#333",
      fontWeight: isSelected ? "bold" : "normal",
    }),
    multiValue: (provided, { data }) => ({
      ...provided,
      backgroundColor: data.color,
      borderRadius: "8px",
      color: "#333",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333", 
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#333",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#e57373",
        color: "white",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "40px",
      overflow: "hidden",
      display: "flex",
      whiteSpace: "nowrap",
    }),
  };

  const isGetPatientsAPIFailure = useSelector(getIsGetPatientsAPIFailure);


  const handleSortRequest = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  // Sorting Function for mouthLab adherence
  const handleAdherenceSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  // Sorting based of user names
  const handleNameSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleCMSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleSubjectIDSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };


  const handleStatusSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleEhrSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleDobSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleGenderSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleMlIdSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleDiagnosisSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
  
    sortableItem.sort((a, b) => {
      const valueA = a[key] || "";
      const valueB = b[key] || "";
  
      if (direction === false) {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
  
    setPatientListSorting(sortableItem);
  };

  useEffect(() => {
    console.log('props ',props);
    handleChange(defaultOptions);
    setFirstLoad(false);
  }, []);

  // Rendering patient list in table body
  const patientTableBody = patientListSorting.map((pat) => (
    <TableBody
      key={pat.guid}
      subjectGUID={pat.guid}
      subjectId={pat.subjectId}
      deviceId={pat.deviceId}
      firstName={pat.firstName}
      lastName={pat.lastName}
      gender={pat.gender}
      adherence={pat.adherence}
      adherence16={pat.adherence16}
      primaryDiagnosis={pat.diagnosis}
      elapsedHours={pat.elapsedHours}
      patientStatus={pat.status}
      registeredBy={pat.registeredBy}
      ehrId={pat.ehrId}
      dob={pat.dob}
      program1={pat.program1}
      program2={pat.program2}
      patientList={props.patientList}
    />
  ));

  const requestSearch = (event) => {
    setSearched(event.target.value);
    filterFunction(searched, selectedOptions);
  };

  const filterFunction = (searchVal, options) => {
    const selectedLabels = options.map((option) => option.label);
    if (!searchVal || searchVal.length === 0) {
      const patientListCopy = props.patientList.map((x) => x);
      if (selectedLabels.length > 0) {
        const filteredPatientsSorted = patientListCopy.filter((patient) =>
          selectedLabels.includes(patient.status)
        );
        setPatientListSorting(filteredPatientsSorted);
      } else {
        setPatientListSorting(patientListCopy);
      }
    } else {
      let filteredPatients = [];
      const patientListCopy = props.patientList.map((x) => x);
      filteredPatients = patientListCopy.filter((row) => {
        let subjectID = 'aidi-' + row.subjectId;
        return (
          (row.status && row.status.toString().toLowerCase().startsWith(searchVal.toLowerCase())) ||
          (row.ehrId && row.ehrId.toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.gender && row.gender.toString().toLowerCase().startsWith(searchVal.toLowerCase())) ||
          (row.diagnosis  && row.diagnosis.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.registeredBy && row.registeredBy.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.lastName && row.lastName.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.firstName && row.firstName.toString().toLowerCase().includes(searchVal.toLowerCase())));
      });

      if (selectedLabels.length > 0) {
        const filteredPatientsSorted = filteredPatients.filter((patient) =>
          selectedLabels.includes(patient.status)
        );
        setPatientListSorting(filteredPatientsSorted);
      } else {
        setPatientListSorting(filteredPatients);
      }
    }
  };

  const handleCancleSearch = (event) => {
    event.preventDefault();
    setSearched("");
    const patientListCopy = props.patientList.map((x) => x);
    setPatientListSorting(patientListCopy);
  };

  return (
    <div>
      <div style={{display:"flex", gridGap: "17px"}}>
      <SearchBar 
                    searched={searched}
                    onSearchChange={(event) => setSearched(event.target.value)}
                    onSearchKeyUp={(event) => requestSearch(event)}
                    onResetSearch={(event) => handleCancleSearch(event)}
                    />
       <Select
           isMulti
           options={options}
           value={selectedOptions}
           onChange={handleChange}
           styles={customStyles}
           placeholder="Select status"
         />
      </div>
      <p></p>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <table className={styles.responsive}>
            <thead>
              <tr>
                <th className={styles.nameHeader} onClick={() => handleNameSorting("lastName")}>
                  Name
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.ehrIdHeader} onClick={() => handleEhrSorting("ehrId")}>
                  EHR ID
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.ehrIdHeader} onClick={() => handleDobSorting("dob")}>
                  DOB
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.genderHeader} onClick={() => handleGenderSorting("gender")}>
                  Gender
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.statusHeader} onClick={() => handleStatusSorting("status")}>
                  Status
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.diagnosisHeader} onClick={() => handleDiagnosisSorting("diagnosis")}>
                  Diagnosis
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.diagnosisHeader} onClick={() => handleCMSorting("program1")}>
                  Programs
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.goalsHeader} onClick={() => handleAdherenceSorting("adherence")}>
                  {/* ML */} Adherence
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.elapsedTimeHeader} onClick={() => handleSortRequest("elapsedHours")}>
                  Elapsed Hours
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.buttonTabHeader}></th>
              </tr>
            </thead>
          </table>

          {
          isGetPatientsAPIFailure ? (<div className={styles.errorMsgsDiv}>
            <div className={styles.noSubjects1}>Network Error: We're having trouble connecting right now.</div>
            <div className={styles.noSubjects2}>Please log out and log back in or try again in a few moments. Thank you for your patience!</div>
          </div>) : patientListSorting.length > 0 ? (
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "69vh", overflowY: "scroll" }}>
              {patientTableBody}
            </Grid>
          ) : searched === '' ? firstLoad ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%` }}>
          <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}/>
        </div>) : selectedOptions.length === 0 ? (
            <div className={styles.noSubjects}>Currently there are no patients registered. Please register a new patient.</div>
          ) : (
            <div className={styles.noSubjects}>No patients meet the filter criteria. Please select a different status.</div>
          ): <div className={styles.noSubjects}>No patients match the search criteria.</div>}

        </Grid>
      </Grid>
    </div>
  );
}
