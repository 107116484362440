import React, { useContext, useEffect, useState,useMemo } from "react";
import styles from "./NotesDrawer.module.css";
import { setShowNotesPanel, setShowNotesDrawer, selectedRecordIdForNotes, setOpenAddNotesPanel, getPatientNotes, setSelectedTimestamp, postPatientNotes, updatePatientNote, setPostNotesSuccessFlag, setSelectedSubjectId } from "../../../../Actions";
import { getSelectedNotesTimestamp, getSelectedNotesVitalsAccOpen } from "../../../../Reducer/selectors/NotesSelector";
import { getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { selectedRecordIdForNotesSelector, getPatientBookmarkedNotesSelector, getPatientNotesSelector, postPatientNotesSuccessorSelector, getOpenAddNotesPanelSelector } from "../../../../Reducer/selectors/NotesSelector";
import { useNavigate, useParams } from "react-router-dom";
import { PatientContext } from "../../../Store/PatientDataProvider";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { globalConstants } from "../../../../Constants/GlobalConstants";
import AddNotes from "./AddNotes";
import NotesDisplay from "./NotesDisplay";
import { Add, BookmarkBorder, FavoriteBorder, Notes } from "@mui/icons-material";

export default function NotesDrawer() {
    dayjs.extend(utc);
    dayjs.extend(timezone);


    const [isNewNotes, setIsNewNotes] = useState(false);
    const [newNotes, setNewNotes] = useState("");
    const [bookmarked, setBookmarked] = useState(0);
    const [tagNames, setTagNames] = useState("");
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const userProfile = useSelector(getUserProfile);
    const userId = userProfile.id;
    const [notes, setNotes] = useState([]);
    const [vitalNotes, setVitalNotes] = useState([]);
    const bookmarkedNotes = useSelector(getPatientBookmarkedNotesSelector);
    const patientInfo = useContext(PatientContext);
    const [expandedAccordion, setExpandedAccordion] = useState("addNotes");

    let subjectId = patientInfo.subjectId;
    let subjectGUID = patientInfo.subjectGUID;
    let deviceIdContext = patientInfo.currentMouthLabId;
    let refreshData = patientInfo.isRefresh;
    let curPatientId = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Selected Notes Timestamp
    let selectedRecordTS = useSelector(getSelectedNotesTimestamp);
    const selectedRecordId = useSelector(selectedRecordIdForNotesSelector);
    const openVitalsAcc = useSelector(getSelectedNotesVitalsAccOpen);

    const patientNotes = useSelector(getPatientNotesSelector);
    const patientNoteSuccessor = useSelector(postPatientNotesSuccessorSelector);
    const openAddNotesPanel = useSelector(getOpenAddNotesPanelSelector);

    const userRole = useSelector(getUserRole);

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        updateNote(notes);
    }

    const handleAccordionToggle = (id) => {
        setExpandedAccordion((prev) => (prev === id ? null : id));
    };

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    useEffect(() => {
        if(openVitalsAcc && selectedRecordId){
            setExpandedAccordion("vitalNotes");
        }else{
            setExpandedAccordion("addNotes");
        }
    }, [openVitalsAcc]);

    const updateNote = (notes) => {
        const payload = {
            id: notes.id,
            subjectId: notes.subjectId,
            isBookmarked: notes.isBookmarked == "false" ? 1 : 0,
        }
        dispatch(updatePatientNote(payload));
    }

    const handleCancelAddNewNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(false));
        setNewNotes("");
        setTagNames("");
        dispatch(setSelectedTimestamp(""));
        dispatch(selectedRecordIdForNotes(''));
    };

    const submitNotes = () => {
        if (newNotes.length === 0 || newNotes.length > 5000 || ((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagNames === "")) {
            return;
        } else {
            const payload = {
                subjectId: selectedSubjectId.selectedSubjectId,
                recordId: selectedRecordId,
                recordTimestamp: selectedRecordTS,
                createdBy: userId,
                note: newNotes,
                isBookmarked: bookmarked,
                tags: tagNames,
                createdDateTime: new Date(),
                timezoneStr: timeZone,
                userRole: userRole,
            }
            dispatch(setShowLoader(true));
            dispatch(postPatientNotes(payload));
            dispatch(setOpenAddNotesPanel(false));
            setNewNotes("");
            setTagNames("");
            dispatch(setSelectedTimestamp(""));
            dispatch(selectedRecordIdForNotes(''));
            if (selectedRecordId && selectedRecordTS) {
                dispatch(setShowNotesDrawer(false));
                dispatch(setOpenAddNotesPanel(false));
                dispatch(setShowNotesPanel(false));
            }
        }
        setExpandedAccordion("recentNotes");
    }

    const handleViewAllNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(false));
        dispatch(setShowNotesDrawer(false));
        dispatch(setShowNotesPanel(false));
        navigate(`/patientDetails/${subjectGUID}/notes/4`);
    }

    const resetNotesState = () => {
        setNewNotes('');
        setBookmarked(0);
        setTagNames("");
    }

    const keepThreeNotes = (notes) => {
        if (notes && notes.length > 0) {
            return notes.slice(0, 3);
        }
    }

    const generateVitalNotes = () => {
        let pNotes = patientNotes;
        let filteredNotes = [];
        if (selectedRecordId) {
            const findRecords = pNotes.filter(note => note.recordId === selectedRecordId);
            if (findRecords && findRecords.length > 0) {
                filteredNotes.push(...findRecords);
            }
        }else{
            const findRecords = pNotes.filter(note => note.recordId !== "null");
            if (findRecords && findRecords.length > 0) {
                const recentThreeVitals = keepThreeNotes(findRecords);
                filteredNotes.push(...recentThreeVitals);
            }
        }
        setVitalNotes(filteredNotes);
    }

    useEffect(() => {
        setNotes(patientNotes);
        if(patientNotes.length>0){
            generateVitalNotes();
        }
    }, [patientNotes]);

    useEffect(() => {
        const payload = {
            subjectId: subjectId || selectedSubjectId.selectedSubjectId,
            recordId: '',
            userRole: userRole,
        }
        dispatch(getPatientNotes(payload));
        dispatch(setOpenAddNotesPanel(false));
    }, []);

    useEffect(() => {
        if (patientNoteSuccessor) {
            resetNotesState();
            dispatch(setPostNotesSuccessFlag(false));
        }
    }, [patientNoteSuccessor]);

    useEffect(() => {
        setIsNewNotes(openAddNotesPanel);
    }, [openAddNotesPanel]);


    useEffect(() => {
        resetNotesState();
        GetPatientInfo(curPatientId.patientId).then((res) => {
            dispatch(setSelectedSubjectId(res.subjectId));
            const payload = {
                subjectId: res.subjectId,
                recordId: '',
                userRole: userRole,
            }
            dispatch(getPatientNotes(payload));
        });
    }, [curPatientId.patientId, deviceIdContext, refreshData]);

    return (
        <div className={styles.noteAccordianContainer}>
            
            {/* New Notes */}
            <div className={styles.noteAccordians}>
                <AddNotes 
                notesIcon={<Add/>}
                    newNotes={newNotes}
                    setNewNotes={setNewNotes}
                    tagName={tagNames}
                    setTagName={setTagNames}
                    isBookmarked={bookmarked}
                    setIsBookmarked={setBookmarked}
                    onSubmit={() => submitNotes()}
                    onCancel={(event) => handleCancelAddNewNotes(event)}
                    expandDefault={expandedAccordion === "addNotes"}
                    onToggle={() => handleAccordionToggle("addNotes")}
                    selectedRecordTS={selectedRecordTS}
                    />
                {/* Recent Notes */}
                <NotesDisplay
                    notes={keepThreeNotes(notes)}
                    notesIcon={<Notes/>}
                    notesTitle={"Recent"}
                    handleBookmarking={handleBookmarking}
                    expandDefault={expandedAccordion === "recentNotes"}
                    onToggle={() => handleAccordionToggle("recentNotes")}
                />

                {/* Bookmarked Notes */}
                <NotesDisplay
                    notes={keepThreeNotes(bookmarkedNotes)}
                    notesIcon={<BookmarkBorder/>}
                    notesTitle={"Bookmarked"}
                    handleBookmarking={handleBookmarking}
                    expandDefault={expandedAccordion === "bookmarkedNotes"}
                    onToggle={() => handleAccordionToggle("bookmarkedNotes")}
                />

                {/* Vital Notes */}
                <NotesDisplay
                    notes={vitalNotes}
                    notesIcon={<FavoriteBorder/>}
                    notesTitle={"Vitals"}
                    handleBookmarking={handleBookmarking}
                    expandDefault={expandedAccordion === "vitalNotes"}
                    onToggle={() => handleAccordionToggle("vitalNotes")}
                />
            </div>

            {/* Footer */}
            <div className={styles.drawerFooter} onClick={(event) => handleViewAllNotes(event)}>
                    View All Notes
                </div>
            
        </div>
    )
}