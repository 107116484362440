import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./TimerDrawer.module.css";
import { setShowNotesPanel, setOpenAddNotesPanel, setSelectedSubjectId, getTimerInfo, addTimerWithNotes, updatePatientNote, setSelectedPageName, getFacilityBPH } from "../../../../Actions";
import { getSelectedProjectIdAndLocationId, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { PatientContext } from "../../../Store/PatientDataProvider";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import AccessAlarmsSharpIcon from "@mui/icons-material/AccessAlarmsSharp";
import { getLatestMonthTimerInfoSelector, getTimerInfoSelector } from "../../../../Reducer/selectors/TimerSelector";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getCurrentPatientGUID, getCurrentPatientSubjectID, getPatientTimerDrawerLoader } from "../../../../Reducer/selectors/UtilSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { Add, BarChartOutlined, ExpandCircleDown, HourglassTopOutlined, Notes } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import LogTime from "./LogTime";
import NotesDisplay from "./NotesDisplay";
import TimerGraph from "./TimerGraph";
import Confetti from "./Confetti";
import { useNavigate } from "react-router-dom/dist";

export default function TimerDrawer(props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const [newNotes, setNewNotes] = useState("");
    const [tagNames, setTagNames] = useState("RPM");
    const userProfile = useSelector(getUserProfile);
    const patientInfo = useContext(PatientContext);
    let refreshData = patientInfo.isRefresh;
    const currPatientGUID = useSelector(getCurrentPatientGUID);
    const currPatientSubjectID = useSelector(getCurrentPatientSubjectID);
    const dispatch = useDispatch();
    const patientTimerDrawerLoader = useSelector(getPatientTimerDrawerLoader);
    const [expandedAccordion, setExpandedAccordion] = useState("monthlyLogs");
    const [bookmarked, setBookmarked] = useState(0);
    const navigate = useNavigate();

    const latestMonthTimerInfo = useSelector(getLatestMonthTimerInfoSelector);
    const previousMonthsTimerInfo = useSelector(getTimerInfoSelector);

    const [latestMonthTimerData, setLatestMonthTimerData] = useState(latestMonthTimerInfo);
    const [previousMonthTimerData, setPreviousMonthTimerData] = useState(previousMonthsTimerInfo);

    let cummulativeMins = Math.floor(latestMonthTimerData.totalDuration / 60);
    let cummulativeSecs = latestMonthTimerData.totalDuration % 60;

    const timerColors = ["rgb(87 199 119)", "#EAA246", "#E55151"];
    const currTimerColor =
        latestMonthTimerData.totalDuration < 600 ? timerColors[2] : latestMonthTimerData.totalDuration < 1200 ? timerColors[1] : timerColors[0];

    const remTimeToNextMilestone =
        latestMonthTimerInfo.totalDuration < 1200
            ? Math.ceil((1200 - latestMonthTimerInfo.totalDuration) / 60)
            : latestMonthTimerInfo.totalDuration < 2400
                ? Math.ceil((2400 - latestMonthTimerInfo.totalDuration) / 60)
                : 0;

    const [milestoneWarningMsg, setMilestoneWarningMsg] = useState(remTimeToNextMilestone + " minutes to reach your milestone");

    const selectedProjLoc = useSelector(getSelectedProjectIdAndLocationId);

    function formatTime1(value) {
        return value < 10 ? `0${value}` : `${value}`;
    }

    const [startTimeSelected, setStartTimeSelected] = useState(null);
    const [endTimeSelected, setEndTimeSelected] = useState(null);

    const submitTimerWithNotes = () => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const payload = {
            subjectId: currPatientSubjectID,
            subjectGuid: currPatientGUID,
            userId: userProfile.id,
            start_time: startTimeSelected,
            end_time: endTimeSelected,
            duration: (endTimeSelected.diff(startTimeSelected, 'minute')) * 60,
            status: "complete",
            note: newNotes,
            tag: tagNames,
            createdDateTime: new Date(),
            timezoneStr: timeZone,
            projectId: selectedProjLoc.selectedProjectId,
            locationId: selectedProjLoc.selectedLocationId,
            isBookmarked: bookmarked,
        };
        dispatch(setShowLoader(true));
        dispatch(addTimerWithNotes(payload));
        dispatch(setOpenAddNotesPanel(false));
        const timerPayload = {
            userId: userProfile.id,
            subjectGuid: currPatientGUID,
            projectId: selectedProjLoc.selectedProjectId,
            locationId: selectedProjLoc.selectedLocationId,
        };
        dispatch(getTimerInfo(timerPayload));
    };

    const resetNotesState = () => {
        setNewNotes("");
    };

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        const isBookmarked = notes.isBookmarked == "false" ? 1 : 0;
        const payload = {
            id: notes.note_record_data.id,
            subjectId: currPatientSubjectID,
            isBookmarked: isBookmarked,
            userId: userProfile.id,
            subjectGuid: currPatientGUID,
            projectId: selectedProjLoc.selectedProjectId,
            locationId: selectedProjLoc.selectedLocationId,
            fromTimer: true,
        }
        dispatch(updatePatientNote(payload));
    }

    useEffect(() => {
        setMilestoneWarningMsg(remTimeToNextMilestone > 0 ? remTimeToNextMilestone + " minutes to reach next milestone" : "Your milestones have been reached");
    }, [remTimeToNextMilestone]);

    useEffect(() => {
        setLatestMonthTimerData(latestMonthTimerInfo);
        setPreviousMonthTimerData(previousMonthsTimerInfo);
        cummulativeMins = Math.floor(latestMonthTimerData.totalDuration / 60);
        cummulativeSecs = latestMonthTimerData.totalDuration % 60;
    }, [previousMonthsTimerInfo, latestMonthTimerInfo]);

    useEffect(() => {
        dispatch(getTimerInfo({ userId: userProfile.id, subjectGuid: currPatientGUID, projectId: selectedProjLoc.selectedProjectId, locationId: selectedProjLoc.selectedLocationId, }));
    }, []);

    useEffect(() => {
        resetNotesState();
        GetPatientInfo(currPatientGUID).then((res) => {
            dispatch(setSelectedSubjectId(res.subjectId));
            const timerPayload = {
                userId: userProfile.id,
                subjectGuid: currPatientGUID,
                projectId: selectedProjLoc.selectedProjectId,
                locationId: selectedProjLoc.selectedLocationId,
            };
            dispatch(getTimerInfo(timerPayload));
        });
    }, [currPatientGUID, userProfile.id, refreshData]);

    const handleAccordionToggle = (id) => {
        setExpandedAccordion((prev) => (prev === id ? null : id));
    };

    const userRole = useSelector(getUserRole);

    const handleViewAllReports = (event) => {
        event.preventDefault();
        dispatch(setShowNotesPanel(false));
        dispatch(setSelectedPageName('reports'));
        let payload = {
        projectId: selectedProjLoc.selectedProjectId,
        locationId: selectedProjLoc.selectedLocationId,
        };
        dispatch(getFacilityBPH(payload));
        navigate("/reports");
    };

    const getFullMonth = (monthString) => {
        return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
            new Date(monthString)
          );
    }

    const parentDivRef = useRef(null);
    const [showConfetti, setShowConfetti] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);

    useEffect(() => {
        if (cummulativeMins >= 20 && (cummulativeMins % 20 >= 0 && cummulativeMins % 20 <= 5)) {
            setTimeout(() => {
                setIsFlipped(true);
            }, 500);
            
            setTimeout(() => {
                setShowConfetti(true);
            }, 1000);

            setTimeout(() => {
                setIsFlipped(false);
                setShowConfetti(false);
            }, 4000);
        }
    }, [cummulativeMins]);

    return (
        
        <div className={styles.timerAccordianContainer}>
            
            {patientTimerDrawerLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) :
                (<>
                    <div className={styles.timerAccordians}>

                    {/* Timer header */}
                    {cummulativeMins >= 0 && cummulativeSecs >= 0 && (
                        <>
                            <div className={styles.currMonthTotalTimeDiv}>
                                <div className={`${styles.displayCard} ${isFlipped ? styles.flipped : ''}`}  ref={parentDivRef}>

                                    {showConfetti && <Confetti parentRef={parentDivRef} showConfetti={showConfetti}/>}

                                    <div className={`${styles.cardSide} ${styles.front}`} >
                                        <div className={styles.topDisplayCard}>
                                            <div className={styles.titleDiv}>
                                            <AccessAlarmsSharpIcon sx={{ height: "30px", width: "30px"}}/><span className={styles.durationHeader}>Total Duration in {getFullMonth(latestMonthTimerData.month)}</span>
                                            </div>
                                            <div className={styles.cummulativeTime} style={{ color: currTimerColor }}>
                                                <div className={styles.cummulativeMins}>
                                                    {formatTime1(cummulativeMins)}
                                                </div>
                                                minutes
                                            </div>
                                        </div>
                                        <div className={styles.bottomDisplayCard}>
                                            <div className={styles.milestoneWarning}>
                                                <HourglassTopOutlined sx={{ height: "20px", width: "20px", color: currTimerColor, marginBottom: "2px" }}/>
                                                {milestoneWarningMsg}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.cardSide} ${styles.back}`}>
                                        Congratulations on completing a milestone!
                                    </div>
                                    </div>
                                </div>
                        </>
                    )}

                    {/* Log Time Accordian */}
                    <LogTime 
                        notesIcon={<Add/>}
                        newNotes={newNotes}
                        setNewNotes={setNewNotes}
                        tagName={tagNames}
                        setTagName={setTagNames}
                        isBookmarked={bookmarked}
                        setIsBookmarked={setBookmarked}
                        onSubmit={() => submitTimerWithNotes()}
                        expandDefault={expandedAccordion === "logTime"}
                        onToggle={() => handleAccordionToggle("logTime")}
                        disabled={userRole === globalConstants.USER_ROLE_CARE_MANAGER || userRole === globalConstants.USER_ROLE_NON_BILLING_PHYSICIAN}
                        setStartTimeSelected = {setStartTimeSelected}
                        setEndTimeSelected={setEndTimeSelected}
                        />

                    {/* Recent Logs Accordian  */}
                    <NotesDisplay
                        notes={latestMonthTimerData.monthWiseTimers}
                        notesIcon={<Notes/>}
                        notesTitle={"Recent Logs"}
                        handleBookmarking={handleBookmarking}
                        expandDefault={expandedAccordion === "recentLogs"}
                        onToggle={() => handleAccordionToggle("recentLogs")}
                        fromTimerObj={{
                            recentLogs: true
                        }}
                    />

                    {/* Monthly Logs Accordian */}
                    <Accordion expanded={expandedAccordion === "monthlyLogs"} onChange={() => handleAccordionToggle("monthlyLogs")} className="custom-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandCircleDown style={{
                                color: expandedAccordion === "monthlyLogs" ? "#AA346F" : "#D491B3"
                            }}/>}
                            className="accordion-summary"
                            style={{
                                backgroundColor: expandedAccordion === "monthlyLogs" ? "#EFC5D9" : "#F3E8EE"
                            }}
                            >
                            <div className="icon-container">
                                <BarChartOutlined/>
                            </div>
                            <div className="accordion-title">
                                Monthly Logs
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                        <div
                        className="drawer-container"
                        style={{
                            width: "100%",
                            overflow: "hidden",
                            position: "relative",
                        }}
                        >
                            {previousMonthTimerData.timerMonthlyInfo ? <TimerGraph graphData={previousMonthTimerData.timerMonthlyInfo} /> : <></>}
                        </div>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                    {/* Footer */}
                    <div className={styles.drawerFooter} onClick={(event) => handleViewAllReports(event)}>
                        View All Reports
                    </div>
                </>)}
        </div>
    );
}