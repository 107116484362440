import { put, takeLatest } from "redux-saga/effects";
import * as timerActions from "../Actions/TimerActions";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
import { setShowLoader, setShowPatientTimerDrawerLoader } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import { getTimerInfo } from "../Actions/TimerActions";

function* getTimerInfoSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_TIMER_INFO = `${domainCF}/api/getTimerInfo/${action.payload.userId}/${action.payload.subjectGuid}/${action.payload.projectId}/${action.payload.locationId}`;

  try {
    //console.log("api:", GET_TIMER_INFO);
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_TIMER_INFO, headers, {}, "GET");
    //console.log(" GET TIMER INFO: ", response);
    //let alteredResp = response;
    if (!response) {
      yield put(timerActions.getTimerInfoFailure());
    } else {
      yield put(timerActions.getTimerInfoSuccess(response));
      /* if (response.timerMonthlyInfo && response.timerMonthlyInfo.length > 2) {
         let temp1 = response.timerMonthlyInfo[0];
         let temp2 = response.timerMonthlyInfo[1];
         alteredResp.timerMonthlyInfo[0] = temp2;
         alteredResp.timerMonthlyInfo[1] = temp1;
       } 
       yield put(timerActions.getTimerInfoSuccess(alteredResp));*/
      if (response.timerMonthlyInfo && response.timerMonthlyInfo.length > 0) {
        yield put(timerActions.setLatestMonthTimerInfo(response.timerMonthlyInfo[3]));
      }

      if (response.timerMonthlyInfo && response.timerMonthlyInfo.length > 1) {
        yield put(timerActions.setPreviousMonthTimerInfo(response.timerMonthlyInfo));
      }
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("GET TIMER INFO Saga ERROR ", e);
    throw e;
  }
  yield put(setShowLoader(false));
  yield put(setShowPatientTimerDrawerLoader(false));
}

function* getIsLastTimerIncompleteSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_IS_LAST_TIMER_INCOMPLETE_URL = `${domainCF}/api/isLastRecordIncomplete/${action.payload.userId}/${action.payload.subjectId}`;

  try {
    //console.log("GET_IS_LAST_TIMER_INCOMPLETE_URL:", GET_IS_LAST_TIMER_INCOMPLETE_URL);
    const response = yield Transport.genericOperation(GET_IS_LAST_TIMER_INCOMPLETE_URL, headers, {}, "GET");
    //console.log("GET_IS_LAST_TIMER_INCOMPLETE response: ", response);

    if (!response) {
      yield put(timerActions.getIsLastTimerIncompleteFailure());
    } else {
      yield put(timerActions.getIsLastTimerIncompleteSuccess(response));
    }
  } catch (e) {
    console.error("GET_IS_LAST_TIMER_INCOMPLETE Saga ERROR ", e);
    throw e;
  }

}

function* addTimerWithNotesSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const ADD_TIMER_WITH_NOTES_URL = `${domainCF}/api/addTimerWithNote/`;

  try {
    const response = yield Transport.genericOperation(ADD_TIMER_WITH_NOTES_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(timerActions.addTimerWithNotesFailure());
    } else {
      //console.log("Add Timer w Notes response ", response);
      yield put(timerActions.addTimerWithNotesSuccess(response));
      yield put(getTimerInfo({ userId: action.payload.userId, subjectGuid: action.payload.subjectGuid, projectId: action.payload.projectId, locationId: action.payload.locationId }));
    }
  } catch (e) {
    console.error("addTimerWithNotesSaga Saga ERROR ", e);
    throw e;
  }
  yield put(setShowLoader(false));
}

export default function* timerSaga() {
  try {
    yield takeLatest(timerActions.getTimerInfo, getTimerInfoSaga);
    yield takeLatest(timerActions.addTimerWithNotes, addTimerWithNotesSaga);
    yield takeLatest(timerActions.getIsLastTimerIncomplete, getIsLastTimerIncompleteSaga);
  } catch {
    yield;
  }
}