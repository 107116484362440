import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles={
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
}

export default class Confetti extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
    this.canvasRef = React.createRef();
  }

  // Method to trigger confetti burst
  makeShot = (particleRatio, opts) => {
    if (this.animationInstance) {
      this.animationInstance({
        ...opts,
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  };

  fire = () => {
    // Bottom-left corner → Top-right direction (angle: 45°)
    this.makeShot(0.25, { spread: 26, startVelocity: 55, angle: 45, origin: { x: 0, y: 1 } });
    this.makeShot(0.2, { spread: 60, angle: 45, origin: { x: 0, y: 1 } });
    this.makeShot(0.35, { spread: 100, decay: 0.91, scalar: 0.8, angle: 45, origin: { x: 0, y: 1 } });
    this.makeShot(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2, angle: 45, origin: { x: 0, y: 1 } });
    this.makeShot(0.1, { spread: 120, startVelocity: 45, angle: 45, origin: { x: 0, y: 1 } });
  
    // Bottom-right corner → Top-left direction (angle: 135°)
    this.makeShot(0.25, { spread: 26, startVelocity: 55, angle: 135, origin: { x: 1, y: 1 } });
    this.makeShot(0.2, { spread: 60, angle: 135, origin: { x: 1, y: 1 } });
    this.makeShot(0.35, { spread: 100, decay: 0.91, scalar: 0.8, angle: 135, origin: { x: 1, y: 1 } });
    this.makeShot(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2, angle: 135, origin: { x: 1, y: 1 } });
    this.makeShot(0.1, { spread: 120, startVelocity: 45, angle: 135, origin: { x: 1, y: 1 } });
  };
  

  // Capture the confetti animation instance
  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  componentDidMount() {
    const { parentRef, showConfetti } = this.props;
  
    if (parentRef?.current && this.canvasRef?.current?.canvas) {
      parentRef.current.appendChild(this.canvasRef.current.canvas);
    } else {
      console.warn('Confetti canvas or parentRef not available: ignore the warning');
    }

    if (showConfetti) {
        this.fire();
      }
  }
  
  componentWillUnmount() {
    const { parentRef } = this.props;
  
    if (parentRef?.current && this.canvasRef?.current?.canvas) {
      parentRef.current.removeChild(this.canvasRef.current.canvas);
    } else {
      console.warn('Confetti canvas or parentRef not available: ignore the warning');
    }
  }

  render() {
    
    return (
      <div>
        {/* Canvas attached dynamically */}
        <ReactCanvasConfetti
          refConfetti={this.getInstance}
          style={canvasStyles}
          ref={this.canvasRef}
        />
      </div>
    );
  }
}
