import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./PatientNotes.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns";
import { DateContext } from "../../../Store/DateProvider";
import Button from "@mui/material/Button";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useDispatch, useSelector ,shallowEqual } from "react-redux";
import { getPatientNotesSelector } from "../../../../Reducer/selectors/NotesSelector";
import { getSelectedSubjectId, getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { setOpenAddNotesPanel, setShowNotesDrawer, setShowNotesPanel } from "../../../../Actions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { getPatientNotes } from "../../../../Actions";
import subDays from "date-fns/subDays";
import Input from '@material-ui/core/Input';
import SendIcon from '@mui/icons-material/Send';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { updatePatientNote } from "../../../../Actions";
import { selectedRecordIdForNotesSelector, getSelectedNotesTimestamp } from "../../../../Reducer/selectors/NotesSelector";
import { useParams } from "react-router-dom";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import { setShowLoader, setShowPatientNotesLoader } from "../../../../Actions/UtilActions";
import { postPatientNotes } from "../../../../Actions";
import { getPageLoader, getPatientNotesLoader } from "../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { globalConstants } from "../../../../Constants/GlobalConstants";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Radio from '@mui/material/Radio';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddEditNotes from '../AddEditNotes/AddEditNotes'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileIcon from "../../../../Assets/icons/Gray-Profile-Icon.svg";
import { useDeleteSubjectVitalsNote } from './useDeleteSubjectVitalsNote'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HtmlTooltip from "../../../../Utils/HtmlTooltip";
import { AddCircle } from "@material-ui/icons";
import SearchBar from "../../../../Utils/SearchBar";

export default function PatientNotes() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const dispatch = useDispatch();
    const [isCalOpen, setIsCalOpen] = useState(false);
    const patientNotes = useSelector(getPatientNotesSelector);
    const [notes, setNotes] = useState([]);
    const selectedSubjectId = useSelector(getSelectedSubjectId,shallowEqual);
    const refOne = useRef(null);
    const [searched, setSearched] = useState("");
    const selectedRecordId = useSelector(selectedRecordIdForNotesSelector);
    const [patientDetailInfo, setPatientDetailInfo] = useState({});

    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const [openAddEditNote , setOpenAddEditNote]= useState(false)
    const userName = firstName + " " + lastName;
    const userId = userProfile.id;
    let curPatientId = useParams();
    const loader = useSelector(getPatientNotesLoader);
    const timeZoneFromDayjs = dayjs.tz.guess();
    const [selectedProgram, setSelectedProgram] = useState("");
    const [programFilters, setProgramFilters] = useState(["RPM", "CCM", "GENERAL"]);
    const userRole = useSelector(getUserRole);
    const [editViewData, setEditViewData] = useState(null);
    const { deleteSubjectVitalsNote, loading, error } = useDeleteSubjectVitalsNote();
    const [currentNoteForConfirmation, setCurrentNoteForConfirmation] = useState(null);

    const [dateRangeApplied, setDateRangeApplied] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [dateRangeSelected, setDateRangeSelected] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    useEffect(() => {
        dispatch(setOpenAddNotesPanel(false));
        dispatch(setShowNotesDrawer(false));
        dispatch(setShowNotesPanel(false));
        dispatch(setShowPatientNotesLoader(true));
        setCurrentNoteForConfirmation(null);
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: '',
            userRole: userRole,
            startDate: dateRangeApplied[0]?.startDate.toISOString().split('T')[0],
            endDate: dateRangeApplied[0]?.endDate.toISOString().split('T')[0],
        }
        const timeoutId = setTimeout(() => {
            dispatch(getPatientNotes(payload));
            dispatch(setOpenAddNotesPanel(false));
            let filteredNotes = applySelectedFilters(patientNotes);
            setNotes(filteredNotes);
        }, 1500); 
        
        return () => clearTimeout(timeoutId);
        
    }, []);

    useEffect(() => {
        let filteredNotes = applySelectedFilters(patientNotes);
        setNotes(filteredNotes);
    }, [patientNotes, selectedProgram]);

    useEffect(() => {
        let isMounted = true;

        GetPatientInfo(curPatientId.patientId).then((res) => {
            if (isMounted) {
                setPatientDetailInfo(res);
            }
        });
        return () => {
            isMounted = false; // Cleanup flag on unmount
        };

    }, [curPatientId.patientId]);

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setDateRangeSelected(dateRangeApplied);
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    const fetchPatientNotes = () => {
        setIsCalOpen(false);
        dispatch(setShowPatientNotesLoader(true));
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: '',
            userRole: userRole,
            startDate: dateRangeSelected[0]?.startDate.toISOString().split('T')[0],
            endDate: dateRangeSelected[0]?.endDate.toISOString().split('T')[0]
        }
        setDateRangeApplied(dateRangeSelected);
        dispatch(getPatientNotes(payload));
        
    }

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setIsCalOpen(false);
        }
    };

    const handleCalChanges = (item) => {
        setDateRangeSelected([item.selection]);
    };

    const searchNotes = (event) => {
        setSearched(event.target.value);
        let searchValue = event.target.value;
        let filteredNotes = patientNotes;

        if (!searchValue || searchValue.length === 0) {
            setNotes(filteredNotes);
        } else {
            filteredNotes = patientNotes && patientNotes.filter((note) => (note.note.toLowerCase().includes(searchValue.toLowerCase()) || (note.createdBy ? note.createdBy.toLowerCase().includes(searchValue.toLowerCase()) : false)));
            setNotes(filteredNotes);
        }
    }

    const handleCancleSearch = (event) => {
        event.preventDefault();
        setSearched("");
        setNotes(patientNotes);
    }

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        updateNote(notes);
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const updateNote = (notes) => {
        const payload = {
            id: notes.id,
            subjectId: notes.subjectId,
            note: notes.note,
            isBookmarked: notes.isBookmarked == "false" ? 1 : 0,
            tags: notes.tags,
            timezoneStr: timeZone,
            userRole: userRole,
            startDate: dateRangeSelected[0]?.startDate.toISOString().split('T')[0],
            endDate: dateRangeSelected[0]?.endDate.toISOString().split('T')[0]
        }
        dispatch(updatePatientNote(payload));
    }

    const editNote = (data) => {
        const payload = {
            id: data.id,
            note: data.newNotes,
            subjectId:selectedSubjectId.selectedSubjectId,
            isBookmarked: data.isBookmarked === false ? 0 : 1,
            tags: data.tagName,
            timezoneStr: timeZone,
            userRole: userRole,
            startDate: dateRangeSelected[0]?.startDate.toISOString().split('T')[0],
            endDate: dateRangeSelected[0]?.endDate.toISOString().split('T')[0]
        }
        dispatch(updatePatientNote(payload));
    }

    const onAddEditNote = (data) => {
        setEditViewData(null);
        const { newNotes, isBookmarked, tagName ,mode } = data
        if (mode == "edit") {
            editNote(data)
            return
        }
        if (newNotes.length <= 0 || newNotes.length > 5000 ||((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagName === "")) {
            return;
        }
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: selectedRecordId,
            createdBy: userId,
            note: newNotes,
            isBookmarked: isBookmarked  === false ? 0 : 1,
            tags: tagName,
            createdDateTime: new Date(),
            timezoneStr: timeZone,
            userRole: userRole,
            startDate: dateRangeSelected[0]?.startDate.toISOString().split('T')[0],
            endDate: dateRangeSelected[0]?.endDate.toISOString().split('T')[0]
        }
        dispatch(setShowPatientNotesLoader(true));
        dispatch(postPatientNotes(payload));
    }

    function ColorfulTagsGen({ tags }) {
    if (!tags || tags.length === 0) {
        return null;
    }
    return (
        <>
            {tags === "RPM" && (
                <div className={styles.selectedTagRPM}>
                    {tags}
                </div>
            )}
            {tags === "CCM" && (
                <div className={styles.selectedTagCCM}>
                    {tags}
                </div>
            )}
            {tags === "GENERAL" && (
                <div className={styles.selectedTagGeneral}>
                    {tags}
                </div>
            )}
        </>
    );
}

    const applySelectedFilters = (notesList) => {
        let filteredList = notesList.filter((note) => {
            if (selectedProgram === "") {
                return true;
            } else if (note.tags === selectedProgram) {
                return true;
            }
        });

        return filteredList;
    };

    const clearProgramFilter = () => {
        setSelectedProgram("");
    };

    const addToProgramFilter = (event) => {
        event.preventDefault();
        setSelectedProgram(event.target.name);
    };

    const handleDelete = async (noteId) => {
        await deleteSubjectVitalsNote(noteId);
        fetchPatientNotes();
        setCurrentNoteForConfirmation(null);
      };

    const ProgramFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProgramFilters
                    filterList={programFilters}
                    setSelectedProgram={setSelectedProgram}
                    selectedProgram={selectedProgram}
                    popupState={props.popupState}
                />
            </div>
        );
    };

    const RenderProgramFilters = (props) => {
        const handleProgramSelect = (event) => {
            props.setSelectedProgram(event.target.name);
            props.popupState.close();
        };

        return (
            <FormGroup>
                {props.filterList &&
                    props.filterList.map((filter) => (
                        <FormControlLabel
                            key={filter}
                            className={
                                props.selectedProgram === filter.toString()
                                    ? styles.filtersDivTextSelected
                                    : styles.filtersDivText
                            }
                            control={
                                <Radio
                                    size="small"
                                    sx={{
                                        color: "#AA346F",
                                        fontSize: "14px !important",
                                        "&.Mui-checked": { color: "#AA346F" },
                                    }}
                                    name={filter.toString()}
                                    checked={props.selectedProgram === filter.toString()}
                                    onChange={handleProgramSelect}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: "14px",
                                        fontFamily: "GTWalsheimProRegular",
                                    }}
                                >
                                    {filter.toString()}
                                </span>
                            }
                        />
                    ))}
            </FormGroup>
        );
    };

    return (
        <div className={styles.rootDiv}>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose a date range" classes={{ tooltip: styles.tooltip }}>
                        <div className={styles.datePickerIconContainer} onClick={(event) => handleCalClick(event)}>
                            <CalendarMonthIcon ></CalendarMonthIcon>
                            <div className={styles.dateInputDiv}>
                                <input
                                    value={`${format(dateRangeSelected[0].startDate, "yyyy-MM-dd")}  |  ${format(dateRangeSelected[0].endDate, "yyyy-MM-dd")}`}
                                    readOnly
                                    className={styles.dateInput}
                                />
                            </div>
                        </div>
                    </Tooltip>
                    <Button className={styles.buttonAddNote} variant="outlined" disabled={openAddEditNote} onClick={() => setOpenAddEditNote(true)}>
                        <span className={styles.newNoteText}>Add Notes</span>
                        <AddCircle/>
                    </Button>
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#aa346f"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
                            <div className={styles.applyButton} onClick={() => fetchPatientNotes()}> Apply </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.notesTopBar}>
                <SearchBar 
                    searched={searched}
                    onSearchChange={(event) => setSearched(event.target.value)}
                    onSearchKeyUp={(event) => searchNotes(event)}
                    onResetSearch={(event) => handleCancleSearch(event)}
                    />
                
                {(userRole === globalConstants.USER_ROLE_AIDAR_ADMIN ||
                    userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && (
                        <PopupState
                            variant="popper"
                            popupId="demo-popup-popper"
                            placement="bottom-start"
                        >
                            {(popupState) => (
                                <div
                                    className={
                                        selectedProgram !== ""
                                            ? styles.filterDivSelected
                                            : styles.filterDiv
                                    }
                                    {...bindToggle(popupState)}
                                >
                                    <Radio
                                        size="small"
                                        sx={{
                                            color: "#AA346F",
                                            "&.Mui-checked": { color: "#AA346F" },
                                        }}
                                        checked={true}
                                    ></Radio>
                                    <div className={styles.filterDivLabel}>
                                        {selectedProgram !== "" ? selectedProgram : "Program"}
                                    </div>
                                    <div>
                                        {selectedProgram !== "" ? (
                                            <CloseIcon
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    clearProgramFilter();
                                                    popupState.close();
                                                }}
                                                style={{
                                                    marginTop: "6px",
                                                    color: "#4E253A",
                                                    height: "16px",
                                                    width: "16px",
                                                }}
                                            ></CloseIcon>
                                        ) : (
                                            <ExpandMoreIcon
                                                style={{
                                                    marginTop: "6px",
                                                    color: "#4E253A",
                                                    height: "20px",
                                                    width: "20px",
                                                }}
                                            ></ExpandMoreIcon>
                                        )}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <ProgramFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>
                    )}
            </div>
            <div className={styles.allNotesDiv}>
                <div className={styles.showNotesDiv} id="scrollable-container">
                    { (loader||loading) ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%`, width: `100%` }}>
                        <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
                    </div>) : (notes && notes.length > 0) ? (notes.map((note) =>
                        <div className={styles.noteBlock} key={note.id}>
                            <div className={styles.noteHeader}>
                                <div className={styles.createdDiv}>
                                   
                                    <div className={styles.createdBy}>
                                    <img src={ProfileIcon} />
                                        {note.createdBy}
                                    </div>
                                    <div className={styles.createdTime}>
                                        {dayjs.utc(note.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </div>
                                <div style={{display:"flex",height:"100%"}}>
                                    <ColorfulTagsGen tags={note.tags} />
                                <div className={styles.actionIcons}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                        <div className={styles.tootlTipHeading}>
                                        {userName!==note.createdBy && note.createdBy !==userId ? "No Access" : "Edit"}</div>
                                        </React.Fragment> }>
                                        <span><IconButton
                                            disabled={userName!==note.createdBy && note.createdBy !==userId}
                                            size="small"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setEditViewData({editNote:note}); 
                                                setOpenAddEditNote(true); 
                                            }}
                                        >
                                            <EditIcon className={styles.actionIcon} style={ userName!==note.createdBy && note.createdBy !==userId? {color:"#BEBABC", cursor:"not-allowed", pointerEvents:"auto"} : {color:"#AA346F"}} />
                                        </IconButton></span></HtmlTooltip>
                                        <HtmlTooltip className={styles.confirmationTooltipContainer}
                                            title={currentNoteForConfirmation === note.id ? (
                                                <React.Fragment>
                                                <div className={`${styles.confirmationTooltip}`}>
                                                    <div className={styles.confirmationText}>
                                                        <b>Do you want to delete this note?</b> This action cannot be undone.
                                                    </div>
                                                    <div className={styles.confirmationButtons}>
                                                        <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        className={styles.confirmationDeleteButton}
                                                        onClick={() => handleDelete(note.id)} // Confirm
                                                        >
                                                        Delete
                                                        </Button>
                                                        <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        className={styles.confirmationCancelButton}
                                                        onClick={() => setCurrentNoteForConfirmation(null)} // Cancel
                                                        >
                                                        Cancel
                                                        </Button>
                                                    </div>
                                                    </div>
                                                </React.Fragment>): "Closing"}
                                                open={currentNoteForConfirmation === note.id && currentNoteForConfirmation}
                                                PopperProps={{
                                                    container: document.querySelector('#scrollable-container')
                                                  }}
                                                customStyles={{
                                                    backgroundColor: "#FFFFFF !important",
                                                    border: "none !important",
                                                    margin: "0px !important"
                                                }}
                                                  >
                                                    <span><HtmlTooltip
                                            title={<div className={styles.tootlTipHeading}>
                                                {userName!==note.createdBy && note.createdBy !==userId ? "No Access" : "Delete"}</div>}>
                                        <span><IconButton
                                            disabled={userName!==note.createdBy && note.createdBy !==userId}
                                            size="small"
                                            onClick={() => setCurrentNoteForConfirmation(currentNoteForConfirmation === note.id ? null : note.id)}>
                                                {
                                                    <DeleteIcon className={styles.actionIcon} style={userName!==note.createdBy && note.createdBy !==userId? {color:"#BEBABC", cursor:"not-allowed", pointerEvents:"auto"} :{color:"#AA346F"}} />
                                                }
                                            
                                        </IconButton></span></HtmlTooltip></span></HtmlTooltip>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                <div className={styles.tootlTipHeading}>Bookmark</div>
                                                </React.Fragment>}>
                                        <span><IconButton
                                            size="small"
                                            onClick={(event) => handleBookmarking(event, note)}
                                        >
                                            {note.isBookmarked === "true" ? (<BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon className={styles.pinButton} style={{color:"#AA346F"}}></BookmarkBorderOutlinedIcon>)}
                                        </IconButton></span></HtmlTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.noteTextArea}>
                                <div className={styles.notesDisplayer}>
                                    {note.note}
                                </div>

                                <div className={styles.noteFooter}>
                                    {note.recordTimestamp ? <div className={styles.noteMeasurementTime}>
                                        {dayjs.utc(note.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div> : <></>
                                    }
                                    <div className={styles.expandIcon}>
                                    {note.isEdited === "true" && <div className={styles.editedNote}>
                                        Edited at {dayjs.utc(note.updateDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                    }
                                    <IconButton size="small"  onClick={(event) => {
                                        event.preventDefault();
                                        setEditViewData({ viewNote: note });
                                        setOpenAddEditNote(true);
                                    }
                                    }>
                                        <MoreHorizIcon />
                                    </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>)) : (
                        <div style={{
                            display: "flex",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            fontFamily: "GTWalsheimProRegular",
                            fontSize: "26px",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            color: "#4E253A",
                            overflowY: "hidden"
                        }}>
                            No notes available!
                        </div>
                    )}
                </div>
                
            </div>
            {openAddEditNote && <AddEditNotes
                data={editViewData}
                patientInfo={patientDetailInfo}
                onClose={() => {
                    setEditViewData(null);
                    setOpenAddEditNote(false)
                }}
                onSave={(data) => {
                    onAddEditNote(data);
                    setOpenAddEditNote(false)
                }} />}
        </div >
    );
}