import React, { useContext, useEffect , useMemo } from "react";
import styles from "./PatientDetails.module.css";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import PatientDetailsTabs from "./PatientDetailsTabs/PatientDetailsTabs";
import { PatientContext } from "../../Store/PatientDataProvider";
import { useDispatch, useSelector } from "react-redux";
import { getAidiBaselinePayload, getAidiBaselinePayloadSuccess, getAidiBrPayload, getAidiBrPayloadSuccess, getAidiHrPayload, getAidiHrPayloadSuccess, getDiagnosesPayload, getLatestBaseline, getPatientInfo, selectedRecordIdForNotes, setIsAddBaselineMode, setIsAddBaselineModeDone, setIsAidiValidating, setIsThereNewBaselineRec, setOpenAddNotesPanel, setPatientStatus, setValidationMessage } from "../../../Actions";
import Avatar from "@material-ui/core/Avatar";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import ProfileIcon from "../../../Assets/Images/ProfileIcon.png";
import { setCurrentPatientGUID, setCurrentPatientSubjectID, setPatientMainTab, setShowAidiValidationLoader, setShowLoader, setShowPatientGraphsLoader, setShowPatientProfilePageLoader, setShowPatientTimerDrawerLoader, setShowThresholdsLoader } from "../../../Actions/UtilActions";
import PatientProfile from "./PatientProfile/PatientProfile";
import { setSelectedMlId, setShouldAbortTimer, setShowNotesDrawer, setShowNotesPanel, setShowTimerDrawer, setShowTimerNotCompleteDialog } from "../../../Actions/DashboardActions";
import { makeStyles } from "@material-ui/core/styles";
import { getCurSubjectStatus, getSelectedMlIdSelector, getShowNotesDrawer, getShowNotesPanel, getShowTimerDrawer, getShowTimerNotCompleteDialog, getTimerState, getUserProfile, getUserRole } from "../../../Reducer/selectors/DashboardSelector";
import { setSelectedTimestamp } from "../../../Actions";
import Timer from "./Timer/Timer";
import { getLatestMonthTimerInfoSelector } from "../../../Reducer/selectors/TimerSelector";
import Backdrop from '@mui/material/Backdrop';
import { getAllPatientsList, getCurrentPatientGUID, getPatientMainTab, getPatientProfilePageLoader } from "../../../Reducer/selectors/UtilSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { subDays } from "date-fns";
import Tooltip from '@mui/material/Tooltip';
import { getDisplayLabelWithoutEhrId } from "../../../Utils/GlobalFunctions";
import { getPatientInfoSelector } from "../../../Reducer/selectors/PatientSelector";
import { getPageLoader } from "../../../Reducer/selectors/UtilSelector";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { globalConstants } from "../../../Constants/GlobalConstants";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SideDrawer from "./Drawers/SideDrawer";


export default function PatientDetails() {
  const dispatch = useDispatch();
  const patientDetailInfo = useSelector(getPatientInfoSelector);
  const selectedMainTab = useSelector(getPatientMainTab);
  const patientInfo = useContext(PatientContext);
  const patientList = useSelector(getAllPatientsList);
  const navigate = useNavigate();
  const timerState = useSelector(getTimerState);
  const timerIncompleteDialog = useSelector(getShowTimerNotCompleteDialog);
  const currPatientGUID = useSelector(getCurrentPatientGUID);
  const patientProfilePageLoader = useSelector(getPatientProfilePageLoader);

  const mlVitalsSelectedTab = useSelector(
    (state) => state.dashboardReducer?.mlvitalsWrapperId,
    (prev, next) => prev === next
  );

  const curSubjectStatus = useSelector(getCurSubjectStatus);

  const selectedMlId = useSelector(getSelectedMlIdSelector);
  const role = useSelector(getUserRole);
  const loader = useSelector(getPageLoader);

  function SplitTime(hours) {
    var Days = Math.floor(hours / 24);
    var Remainder = hours % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return { Days: Days, Hours: Hours, Minutes: Minutes };
  }

  var timeResult = "--";
  parseInt(patientDetailInfo.elapsedHours) === -1 ? (timeResult = "--") : (timeResult = SplitTime(patientDetailInfo.elapsedHours));

  // Notes drawer and panel actions
  let drawerOneAction = useSelector(getShowNotesPanel);
  let drawerTwoAction = useSelector(getShowNotesDrawer);
  let timerDraweAction = useSelector(getShowTimerDrawer);

  let drawerState = useMemo(
    () => ({
      drawerOne: drawerOneAction,
      drawerTwo: drawerTwoAction,
      timerDrawer: timerDraweAction,
    }),
    [drawerOneAction, drawerTwoAction, timerDraweAction]
  );

  const classes = useStyles(drawerState);

  const handleCloseDrawer = (event) => {
    event.preventDefault();
    dispatch(setShowNotesPanel(!drawerState.drawerOne));
    dispatch(selectedRecordIdForNotes(''));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setSelectedTimestamp(""));
  };

  const latestMonthTimerInfo = useSelector(getLatestMonthTimerInfoSelector);

  const onChangeMainTab = (newValue) => {
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(newValue));
  };

  const resetAidiStates = () => {
    dispatch(getAidiBaselinePayloadSuccess([]));
    dispatch(getAidiBrPayloadSuccess([]));
    dispatch(getAidiHrPayloadSuccess([]));
    dispatch(setIsAddBaselineMode(false));
    dispatch(setIsAddBaselineModeDone(false));
    dispatch(setIsAidiValidating(false));
    dispatch(setIsThereNewBaselineRec(false));
    dispatch(setValidationMessage(""));
    dispatch(setShowAidiValidationLoader(false));
  };

  const handlePrevPatient = (event) => {
    event.preventDefault();
    resetAidiStates();
    let prevPatientGUID = patientList && patientList[0].guid;
    let prevPatientID = patientList && patientList[0].subjectId;
    let prevPatientMLID = patientList && patientList[0].deviceId;

    if (currPatientGUID === prevPatientGUID) {
      return;
    }

    for (const x of patientList) {

      if (x.guid === currPatientGUID) {
        dispatch(setShowLoader(true));
        dispatch(setCurrentPatientGUID(prevPatientGUID));
        dispatch(setCurrentPatientSubjectID(prevPatientID));
        dispatch(setSelectedMlId(prevPatientMLID));
        patientInfo.setSubjectGUID(prevPatientGUID);
        patientInfo.setSubjectId(prevPatientID);
        patientInfo.setCurrentMouthLabId(prevPatientMLID);
        dispatch(setShowPatientProfilePageLoader(true));
        dispatch(setShowPatientTimerDrawerLoader(true));
        dispatch(setShowPatientGraphsLoader(true));
        let payload = {
          subjectId: prevPatientID,
          startDate: (subDays(new Date(), 120)).toISOString().split('T')[0],
          endDate: (new Date()).toISOString().split('T')[0],
        };
        dispatch(getDiagnosesPayload({ subjectId: prevPatientID }));
        navigate(`/patientDetails/${prevPatientGUID}`);
        dispatch(setShowThresholdsLoader(true));
        dispatch(getLatestBaseline({ subjectGuid: prevPatientGUID }));
      } else {
        prevPatientGUID = x.guid;
        prevPatientID = x.subjectId;
        prevPatientMLID = x.deviceId;
      }
    }
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(0));
  };


  const handleNextPatient = (event) => {
    event.preventDefault();
    resetAidiStates();
    const listLen = patientList.length;

    let count = 0;
    for (count = 0; count < listLen; count++) {
      if (patientList[count].guid && currPatientGUID === patientList[count].guid) {
        break;
      }
    };

    if (count != listLen - 1) {
      dispatch(setShowLoader(true));
      dispatch(setCurrentPatientGUID(patientList[count + 1].guid));
      dispatch(setCurrentPatientSubjectID(patientList[count + 1].subjectId));
      dispatch(setSelectedMlId(patientList[count + 1].deviceId));
      patientInfo.setSubjectGUID(patientList[count + 1].guid);
      patientInfo.setSubjectId(patientList[count + 1].subjectId);
      patientInfo.setCurrentMouthLabId(patientList[count + 1].deviceId);
      dispatch(setShowPatientProfilePageLoader(true));
      dispatch(setShowPatientTimerDrawerLoader(true));
      dispatch(setShowPatientGraphsLoader(true));
      let payload = {
        subjectId: patientList[count + 1].subjectId,
        startDate: (subDays(new Date(), 120)).toISOString().split('T')[0],
        endDate: (new Date()).toISOString().split('T')[0],
      };
      dispatch(getDiagnosesPayload({ subjectId: patientList[count + 1].subjectId }));
      dispatch(getAidiBrPayload(payload));
      dispatch(getAidiHrPayload(payload));
      dispatch(getAidiBaselinePayload(payload));
      navigate(`/patientDetails/${patientList[count + 1].guid}`);
      dispatch(setShowNotesDrawer(false));
      dispatch(setShowTimerDrawer(false));
    }
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(0));
    dispatch(setShowThresholdsLoader(true));
    dispatch(getLatestBaseline({ subjectGuid: patientList[count + 1].guid }));
  };

  useEffect(() => {
    dispatch(setShowPatientProfilePageLoader(true));
    dispatch(getPatientInfo(currPatientGUID));
  }, [currPatientGUID]);

  function closeTimerNotCompleteDialog(event, reason) {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      dispatch(setShowTimerNotCompleteDialog(false));
    }
  };

  function abortTimer() {
    dispatch(setShouldAbortTimer(true));
    dispatch(setShowTimerNotCompleteDialog(false));
  }

  const getPatientLabel = (patientDetailInfo) => {
    let displayName = "";
    if (patientDetailInfo.contactInfo.firstName != "") {
      let fullName = getDisplayLabelWithoutEhrId(patientDetailInfo.contactInfo.firstName, patientDetailInfo.contactInfo.lastName);
      if (fullName.length > 15) {
        return fullName.substring(0, 15) + "...";
      } else {
        return fullName;
      }
    }
    const subjectlabel = "ADR - " + patientDetailInfo.subjectId;
    return subjectlabel;
  };

  const updatePatientStatus = (newStatus, event, popupState) => {
    event.preventDefault();
    const payload = {
      patientGuid: currPatientGUID,
      status: newStatus,
    };

    dispatch(setPatientStatus(payload));
  };

  const RenderStatuses = (props) => {
    const dispatch = useDispatch();
    const curSubjectStatus = useSelector(getCurSubjectStatus);
  
    const statuses = [
      { name: "Active", color: "#1EF793" },
      { name: "Calibrate", color: "#FF3C3C" },
      { name: "Registered", color: "#A040AB" },
      { name: "Inactive", color: "rgb(170 171 175)" },
    ];
  
    const handleStatusChange = async (status, event) => {
      dispatch(setShowLoader(true));
      props.popupState.close();
      updatePatientStatus(status, event);
    };
  
    return (
      <div className={styles.wrapper}>
        <FormGroup sx={{
          marginTop: "10px", "& span":
            { padding: "0px !important", margin: "5px 3px 5px 3px" },
          "& div": { display: "flex", alignSelf: "center" }
        }}>
          {statuses.map((status) => (
            <div
              key={status.name}
              onClick={(event) => handleStatusChange(status.name, event)}
              className={
                curSubjectStatus === status.name
                  ? styles[`filtersDivTextSelected${status.name}`]
                  : styles.filtersDivTextActive
              }
            >
              <FormControlLabel
              style={{marginLeft:"0px"}}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: status.color,
                      "&.Mui-checked":{
                        color:status.color
                      }

                    }}
                    name={status.name}
                    checked={true}
                  />
                }
                label={status.name}
              />
            </div>
          ))}
        </FormGroup>
      </div>
    );
  };

  const StatusPanel = (props) => {
    return (
      <div className={styles.wrapper}>
        <RenderStatuses popupState={props.popupState}></RenderStatuses>
      </div>
    );
  }

  if (patientProfilePageLoader) {
    return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
  } else {
    return (
  <div className={styles.rootDiv}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={timerIncompleteDialog}
        ></Backdrop>
        <div>
          <div className={classes.patientDetailsDiv}>
            <Grid container style={{ overflow: "hidden" }}>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.topDiv}>
                  <div className={styles.timerDiv}>
                    {(role !== globalConstants.USER_ROLE_CARE_MANAGER &&
                      role !==
                        globalConstants.USER_ROLE_NON_BILLING_PHYSICIAN) && (
                      <Timer patientDetailInfo={patientDetailInfo}></Timer>
                    )}
                  </div>
                  <div className={styles.topBarButtons}>
                    <PopupState
                      variant="popper"
                      popupId="demo-popup-popper"
                      placement="bottom-start"
                    >
                      {(popupState) => (
                        <ClickAwayListener onClickAway={() => popupState.close()}>
                        <div
                          className={
                            curSubjectStatus === "Active"
                              ? styles.activeStatusDiv
                              : curSubjectStatus === "Registered"
                              ? styles.registeredStatusDiv
                              : curSubjectStatus === "Terminated"
                              ? styles.terminatedStatusDiv
                              : curSubjectStatus === "Calibrate"
                              ? styles.calibrateStatusDiv
                              : styles.completedStatusDiv
                          }
                          {...bindToggle(popupState)}
                        >
                          <Radio
                            size="small"
                            sx={{
                              color:
                                curSubjectStatus === "Active"
                                  ? "#1EF793"
                                  : curSubjectStatus === "Registered"
                                  ? "#A040AB"
                                  : curSubjectStatus === "Terminated"
                                  ? "#999999"
                                  : curSubjectStatus === "Calibrate"
                                  ? "#FF3C3C"
                                  : curSubjectStatus === "Inactive"
                                  ? "rgb(170 171 175)"
                                  : "#35AAF7",
                              "&.Mui-checked": {
                                color:
                                  curSubjectStatus === "Active"
                                    ? "#1EF793"
                                    : curSubjectStatus === "Registered"
                                    ? "#A040AB"
                                    : curSubjectStatus === "Terminated"
                                    ? "#999999"
                                    : curSubjectStatus === "Calibrate"
                                    ? "#FF3C3C"
                                    : curSubjectStatus === "Inactive"
                                    ? "rgb(170 171 175)"
                                    : "#35AAF7",
                              },
                            }}
                            checked={true}
                          ></Radio>
                          <div className={styles.filterDivLabel}>
                            {loader ? "Updating…" : curSubjectStatus}
                          </div>
                          <div>
                            <ExpandMoreIcon
                              style={{
                                marginTop: "6px",
                                color: "#4E253A",
                                height: "20px",
                                width: "20px",
                              }}
                            ></ExpandMoreIcon>
                          </div>
                            <Popper
                              {...bindPopper(popupState)}
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper className={styles.filterBackdrop}>
                                    <StatusPanel popupState={popupState} />
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                        </div>
                        </ClickAwayListener>

                      )}
                    </PopupState>

                    <div className={styles.mlIdDiv}>
                      <div className={styles.mlIdDiv1}>ML ID</div>
                      <div className={styles.mlIdDiv2}>
                        {selectedMlId}
                      </div>
                    </div>

                    <div className={styles.subjectId}>
                      {/* Replace AIDI from site context  */}
                      <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                          <div className={styles.patientIdDiv}>
                            <Avatar className={styles.avatar} alt="avatar_patient" {...bindToggle(popupState)} src={ProfileIcon}></Avatar>
                            <div>{patientDetailInfo.hasOwnProperty("firstname")}
                              {getPatientLabel(patientDetailInfo)}
                            </div>
                            <div className={styles.subjectIdButtons}>
                              <Tooltip title="Previous Profile">
                                <IconButton
                                  size="medium"
                                  className={`${styles.iconButtonWrapper} ${styles.iconButtonLeft}`}
                                  onClick={(event) => handlePrevPatient(event)}
                                  disabled={timerState != 0}
                                >
                                  <KeyboardArrowLeftIcon style={{ color: '#FFFFFF' }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Next Profile">
                                <IconButton
                                  size="medium"
                                  className={`${styles.iconButtonWrapper} ${styles.iconButtonRight}`}
                                  onClick={(event) => handleNextPatient(event)}
                                  disabled={timerState != 0}
                                >
                                  <KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper sx={{ p: 2, marginTop: 1, marginLeft: 30, marginRight: 1 }}>
                                    <PatientProfile patientDetailInfo={patientDetailInfo} popupState={popupState} elapsedHours={timeResult} />
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>
                    </div>
                  </div>
                </div>
              </Grid>

              {/* Contents of the page - Patient Tabs  */}
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.tabsContainer}>
                  <PatientDetailsTabs
                    selectedMainTab={selectedMainTab}
                    onChangeMainTab={onChangeMainTab} />
                </div>
              </Grid>
            </Grid>
          </div >
        </div >

        <SideDrawer
        patientInfo={patientDetailInfo}
          isDrawerOpen={((drawerState.drawerOne || drawerState.drawerTwo) && mlVitalsSelectedTab.selectedMlVitalsTab != 1)}
          isModalOpen={timerIncompleteDialog}
          toggleDrawer={(event) => handleCloseDrawer(event)}
          abortTimer={abortTimer}
          latestMonthTimerInfo={latestMonthTimerInfo}
          role={role}
          onModalClose={(event, reason) => closeTimerNotCompleteDialog(event, reason)}
          onModalAbort={() => abortTimer()}
          onModalSubmit={closeTimerNotCompleteDialog}
        />


      </div >
    );
  }
}


const useStyles = makeStyles((theme) => ({
  patientDetailsDiv: {
    overflowX: "hidden",
  },
}));