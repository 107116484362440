import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
const HtmlTooltip = styled(({ className, customStyles = {}, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, customStyles }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",
      color: "#FFF",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      marginTop: "8px !important",
      fontFamily: 'GTWalsheimProRegular',
      ...customStyles,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#000",
    },
  }));

  export default HtmlTooltip;