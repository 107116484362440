import React, { useRef, useState, useEffect } from "react";
import { Chart } from "react-charts";
import "./ResponsiveChart.css"

function ResponsiveChart({ data, primaryAxis, secondaryAxes }) {
  const chartContainerRef = useRef(null); // Ref for the chart container
  const [dimensions, setDimensions] = useState({ width: 600, height: 218 });

  // Listen to size changes using ResizeObserver
  useEffect(() => {
    const container = chartContainerRef.current;

    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={chartContainerRef}
      className="chart-container"
    >
      
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          defaultWidth: dimensions.width,
          defaultHeight: dimensions.height,
          interactionMode: "primary",
          defaultColors: ["#AA346F"],
          tooltip: {
            align: "auto",
            anchor: "closest",
          },
        }}
      />
    </div>
  );
}

export default ResponsiveChart;
