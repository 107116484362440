import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./SideDrawer.module.css";
import TimerModal from "./TimerModal";
import NotesDrawer from "./NotesDrawer";
import TimerDrawer from "./TimerDrawer";
import CloseIcon from "@mui/icons-material/Close";
import ProfileIcon from "../../../../Assets/icons/Light-Profile-Icon.svg";

const SideDrawer = ({
  patientInfo,
  isDrawerOpen,
  toggleDrawer, // Handles opening and closing
  latestMonthTimerInfo,
  role,
  isModalOpen,
  onModalClose,
  onModalAbort,
  onModalSubmit
}) => {
  const [showNotesTab, setShowNotesTab] = useState(true);
  
  return (
    <>
      <div
        className={`${styles.notesDrawerButton} ${
          isDrawerOpen ? styles.drawerOpen : styles.drawerClosed
        }`}
      >
        <IconButton
          size="large"
          className={styles.iconNotesDrawerButton}
          onClick={toggleDrawer}
        >
          <KeyboardArrowLeftIcon style={{ color: `#4E253A` }} />
        </IconButton>
      </div>

      <Drawer
        className={styles.primaryDrawer}
        variant="temporary"
        anchor="right"
        open={isDrawerOpen}
        classes={{ paper: styles.drawerPaper }}
        ModalProps={{
          hideBackdrop: true, // Remove the dark background
          disableEnforceFocus: true, // Allows focus on background elements
          disablePortal: true,       // Prevents portal behavior
        }}
        PaperProps={{
          style: { pointerEvents: "auto" }, // Ensures the drawer is still interactive
        }}
      >
        
        {/* Header */}
        <div className="header">
        <div
          onClick={toggleDrawer}
          className="close-button">
          <CloseIcon className="close-icon" />
        </div>
        <div className="profile-details">
          <div className="name-and-id">
            <span className="name">{`${patientInfo?.contactInfo?.firstName ?? ""} ${
              patientInfo?.contactInfo?.lastName ?? ""
            }`.trim()}</span>
            <span className="id">
              EHR ID : {patientInfo?.ehrId ?? "None"}
            </span>
          </div>

          <img src={ProfileIcon} alt="Profile Icon" />
        </div>
      </div>

      {/* Tabs */}
      <div className={styles.tabsContainer}>
        <div className={styles.tabs} style={{
          color: showNotesTab ?
          "#FFFFFF" : "#AA346F",
          backgroundColor: showNotesTab ?
          "#B95888" : "#F9F3F6",
          cursor: showNotesTab ?
          "not-allowed" : "pointer"
        }} 
        onClick={() => {setShowNotesTab(true)}}>Notes</div>
        <div className={styles.tabs} style={{
          color: !showNotesTab ?
          "#FFFFFF" : "#AA346F",
          backgroundColor: !showNotesTab ?
          "#B95888" : "#F9F3F6",
          cursor: !showNotesTab ?
          "not-allowed" : "pointer"
        }}
        onClick={() => {setShowNotesTab(false)}}>Timer</div>
        </div>

        {/* Notes Drawer Content */}
        {showNotesTab && <NotesDrawer />}

        {/* Timer Drawer Content */}
        {!showNotesTab && role !== "CARE_MANAGER" && role !== "NON_BILLING_PHYSICIAN" && (<TimerDrawer latestMonthTimerInfo={latestMonthTimerInfo} />)}

      </Drawer>

      <TimerModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        onAbort={onModalAbort}
        onSubmit={onModalSubmit}
      />
    </>
  );
};

export default SideDrawer;