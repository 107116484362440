import React, { useEffect, useMemo, useRef, useState } from "react";
import ResponsiveChart from "./ResponsiveChart";
import "./TimerGraph.css";

export default function TimerGraph({
  graphData
}) {

  const [activeTab, setActiveTab] = useState('RPM');

  const data = useMemo(
    () => [
      {
        label: activeTab,
        data: Array.isArray(graphData) ? graphData : [],
      },
    ],
    [graphData]
  );

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.month,
      scaleType: "band",
      innerBandPadding: 0.3,
      outerBandPadding: 0.06,
      innerSeriesBandPadding: 0.1,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => Math.floor( (parseInt(datum.totalDuration, 10) || 0) / 60 ),
        elementType: "bar",
        min: 0
      },
    ],
    []
  );
  

  useEffect(() => {
    window.addEventListener('error', e => {
        if (e.message.startsWith('ResizeObserver loop')) {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.remove();
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.remove();
            }
        }
    });
  }, []);

  return (
    <>
    {Array.isArray(graphData) && graphData.length > 0 ?
      <div className="graph-box">

      <div className="graph-tabs">
        <div
          className={`tab ${activeTab === 'RPM' ? 'active' : ''}`}
          onClick={() => setActiveTab('RPM')}
        >
          RPM
        </div>
        <div
          className={`tab disabled`}
          // Keep this comment for future updates
          // onClick={() => setActiveTab('CCM')}
        >
          CCM
        </div>
      </div>

        <div className="graph-container">
          {/* Y-Axis Label */}
          <div className="axis-label y-axis-label">Minutes</div>

          <ResponsiveChart
            data={data}
            primaryAxis={primaryAxis}
            secondaryAxes={secondaryAxes}
          />

          {/* X-Axis Label */}
          <div className="axis-label x-axis-label">Months</div>
        </div>
      </div> : <></>}
      {/* Dummy svg for gradient color */}
      <svg width="0" height="0">
      <defs>
      <linearGradient id="gradientFill" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#AA346F" stopOpacity="1" />
        <stop offset="4%" stopColor="#AA346F" stopOpacity="1" />
        <stop offset="5%" stopColor="#f0a4cd" stopOpacity="1" />
        <stop offset="100%" stopColor="#F5E6EE" stopOpacity="1" />
      </linearGradient>
      </defs>
      </svg>
    </>
  );
}
