import React from "react";
import { Modal, Fade, Grid, Button } from "@mui/material";
import logoutImg from '../../../../../src/Assets/Images/Logout.png';
import styles from "./TimerModal.module.css";

const TimerModal = ({ isOpen, onClose, onAbort, onSubmit }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      hideBackdrop={true}
    >
      <Fade in={isOpen}>
        <div className={styles.paper}>
          <div className={styles.menuTitle}>Warning</div>
          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.userQuestionMessage}>
                  This action will end your session. What would you like to do?
                </div>
                <div className={styles.mouthLabImgDiv}>
                  <img
                    src={logoutImg}
                    loading="lazy"
                    className={styles.mlImg}
                    alt="logout warning"
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onAbort}
                    className={styles.cancelButton}
                  >
                    Abort
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    className={styles.submitButton}
                  >
                    Continue
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default TimerModal;
