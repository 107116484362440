import { notesConstants } from "../Constants";

const initialState = {
    patientNotes: [],
    patientNoteAdded: false,
    patientNoteUpdated: false,
    notesTags: [],
    selectedTimestamp: "",
    openAddNotesPanel: false,
    selectedRecordIdForNotes: '',
};

export function notesReducer(state = initialState, action) {
    switch (action.type) {
        case notesConstants.GET_PATIENT_NOTES:
            return {
                ...state,
            };
        case notesConstants.GET_PATIENT_NOTES_SUCCESS:
            return {
                ...state,
                patientNotes: action.payload.notes
            };
        case notesConstants.GET_PATIENT_NOTES_FAILURE:
            return {
                ...state,
                patientNotes: []
            };
        case notesConstants.POST_PATIENT_NOTES:
            return {
                ...state,
            };
        case notesConstants.POST_PATIENT_NOTES_SUCCESS:
            return {
                ...state,
                patientNoteAdded: true,
            };
        case notesConstants.POST_PATIENT_NOTES_FAILURE:
            return {
                ...state,
                patientNoteAdded: false
            };
        case notesConstants.UPDATE_PATIENT_NOTE:
            return {
                ...state,
            };
        case notesConstants.UPDATE_PATIENT_NOTE_SUCCESS:
            return {
                ...state,
                patientNoteUpdated: true,
            };
        case notesConstants.UPDATE_PATIENT_NOTE_FAILURE:
            return {
                ...state,
                patientNoteUpdated: false
            };
        case notesConstants.SET_NOTES_TAGS:
            return {
                ...state,
                notesTags: action.payload
            };
        case notesConstants.SET_POST_NOTES_SUCCESS_FLAG:
            return {
                ...state,
                patientNoteAdded: action.payload
            };
        case notesConstants.SET_SELECTED_TIMESTAMP:
            return {
                ...state,
                selectedTimestamp: action.payload
            };
        case notesConstants.SET_OPEN_ADD_NOTES_PANEL:
            return {
                ...state,
                openAddNotesPanel: action.payload
            };
        case notesConstants.SELECTED_RECORDID_FOR_NOTES:
            return {
                ...state,
                selectedRecordIdForNotes: action.payload
            };
        case notesConstants.ADD_NOTES_TO_RECORD_FROM_TABLE:
            return {
                ...state,
                timeStamp: action.payload.timeStamp,
                recordId: action.payload.recordId,
                showVitalsAccordian: action.payload.showVitalsAccordian,
            };
        default:
            return state;
    }

}