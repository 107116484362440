import React, { useState } from "react";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./NotesDisplay.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ZoomOutMap } from "@material-ui/icons";
import { AccessTime, ExpandCircleDown, Person, ZoomInMap } from "@mui/icons-material";
import HtmlTooltip from "../../../../Utils/HtmlTooltip";

dayjs.extend(utc);
dayjs.extend(timezone);
const timeZoneFromDayjs = dayjs.tz.guess();

    const NotesDisplay = ({
        notes,
        notesIcon,
        notesTitle,
        handleBookmarking,
        expandDefault,
        onToggle,
        fromTimerObj=null
      }) => {

        const [expandedNoteId, setExpandedNoteId] = useState(null);
        const [isClamped, setIsClamped] = useState(true);

        const expandNote = (id) => {
          if (expandedNoteId === id) {
            setExpandedNoteId(null);
            setTimeout(() => {
              setIsClamped(true);
            }, 300);
          } else {
            setExpandedNoteId(id);
            setIsClamped(false);
          }
        };

        const checkNoteLength = ({note}) => {
          const checkNoteLines = note.split("\n");
          let isOverflowing = false;
          if (checkNoteLines.length > 2) {
            isOverflowing = true;
          }else if (checkNoteLines.length > 1 && checkNoteLines[1].length > 90) {
            isOverflowing = true;
          }else if (checkNoteLines[0].length > 176) {
            isOverflowing = true;
          }
          return isOverflowing;
        }

        if (fromTimerObj && notes) {
          notes = notes.map(({ startTime, endTime, noteText, ...rest }) => ({
            createdDateTime: startTime,
            note: noteText,
            ...rest,
          }));
        };

        function formatTime(totalSeconds) {
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
          return `${formattedMinutes}m ${formattedSeconds}s`;
      }

        return (
          
          <Accordion expanded={expandDefault} onChange={onToggle} className="custom-accordion">
            <AccordionSummary
              expandIcon={<ExpandCircleDown style={{
                color: expandDefault ? "#AA346F" : "#D491B3"
              }}/>}
              className="accordion-summary"
              style={{
                backgroundColor: expandDefault ? "#EFC5D9" : "#F3E8EE"
              }}
              
            >
              <div className="icon-container">
                {notesIcon}
              </div>
              <div className="accordion-title">
                {notesTitle}
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="note-main-container">
                {notes && notes.length > 0 && notes.map((note, index) => {
                        const isExpanded = expandedNoteId === note.id;
                        return(<div
                          key={note.id}
                          className={`note-container ${
                            isExpanded ? `expanded expanded-${index}` : "collapsed"
                          } ${expandedNoteId && expandedNoteId !== note.id ? "squeezed" : ""}`}
                        >
                            <>
                          <div className="note-header">
                            <Person className="person-icon"/>

                            <div className="createdBy">{note.createdBy}</div>

                            <div className="createdDateTime">{dayjs.utc(note.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>

                            <div className="flexFill"></div>

                            <div className="tagsBook">
                              <div className={`note-tag ${note.tags}`}>
                                  <span>{note.tags}</span>
                              </div>

                              <div className="bookmark-icon-container">
                                <IconButton
                                  size="small"
                                  onClick={(event) => handleBookmarking(event, note)}
                                  style={{
                                    padding: "0px",
                                  }}
                                >
                                  {note.isBookmarked === "true" ? (
                                    <BookmarkOutlinedIcon className="bookmark-note-icon" />
                                  ) : (
                                    <BookmarkBorderOutlinedIcon className="bookmark-note-icon" />
                                  )}
                                </IconButton>
                              </div>

                              {fromTimerObj!==null ? (
                                <div className="duration">
                                  <AccessTime/>
                                  {formatTime(note.duration)}
                                </div>
                              ):<></>}
                            </div>
                          </div>

                          <div className={`note-content ${isClamped? "":"no-clamp"}`}>
                              <div className="note-body">{note.note}</div>
                          </div>

                          <div className="note-footer">
                            {note.recordTimestamp ? <div className="modifiedDateTime">Vitals taken on {dayjs.utc(note.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div> :
                            <div></div>}
                            {checkNoteLength(note) ? (
                              isExpanded ? (
                                <HtmlTooltip
                                title="Collapse"
                                placement="top"
                                customStyles={{
                                  marginRight: "17px !important",
                                  marginBottom: "4px !important"
                              }}
                              >
                                <ZoomInMap
                                  className="shrinkIcon"
                                  onClick={() => expandNote(note.id)}
                                />
                                </HtmlTooltip>
                              ) : (
                                <HtmlTooltip
                                title="Expand"
                                placement="top"
                                customStyles={{
                                  marginRight: "24px !important",
                                  marginBottom: "4px !important"
                              }}
                              >
                                <ZoomOutMap
                                  className="expandIcon"
                                  onClick={() => expandNote(note.id)}
                                />
                                </HtmlTooltip>
                              )
                            ) : (
                              <HtmlTooltip
                                title="No additional details."
                                placement="top"
                                customStyles={{
                                  marginRight: "35px !important",
                                  marginBottom: "4px !important"
                              }}
                              >
                              <ZoomOutMap
                                className="disabledExpandIcon"
                              />
                              </HtmlTooltip>
                            )}

                          </div>
                          </>
                        </div>
                )})}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      };


export default NotesDisplay;
