import React, { useCallback, useEffect, useState } from "react";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./AddNotes.css";
import HtmlTooltip from "../../../../Utils/HtmlTooltip";
import { AccessTime, East, ExpandCircleDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import "./LogTime.css";

const statusOptions = [
    { label: "RPM", color: "#AA346F", disabled: false },
    { label: "CCM", color: "#7C596A", disabled: true },
  ];

dayjs.extend(utc);
dayjs.extend(timezone);

const TextBoxWithButtons = ({
newNotes,
setNewNotes,
tagName,
setTagName,
isBookmarked,
setIsBookmarked,
}) => {
const handleBookmarkToggle = () => {
    setIsBookmarked((prev) => !prev);
};

const handleStatusSelect = (status) => {
    if (tagName === status) {
        setTagName("");
    } else {
        setTagName(status);
    }
};

return (
    <div className="textbox-container">
    <div className="textarea-wrapper">
        <textarea
        value={newNotes}
        onChange={(e) => setNewNotes(e.target.value)}
        placeholder={"Describe any relevant information about the patient. E.g., I use Omron blood pressure cuff biweekly."}
        maxLength={5000}
        multiline='true'
        disableunderline="true"
        className="note-textarea"
        />
        <div className="character-count">
        {newNotes.length} / 5000 Characters
        </div>
    </div>

    <div className="status-buttons-container">
        {statusOptions.map((status, index) => (
        <button
            key={status.label}
            onClick={() => handleStatusSelect(status.label)}
            className={`status-button ${
            tagName === status.label ? "selected" : ""
            } ${
                status.disabled ? "disabledTag" : ""
                }`}
            style={{
            borderColor: tagName === status.label ? status.color : "#ddd",
            backgroundColor:
                tagName === status.label ? status.color : "#F6EFF4",
            color: tagName === status.label ? "#FFFFFF" : status.color,
            borderRadius:
                index === 0
                ? "0 0 0 8px"
                : index === statusOptions.length - 1
                ? "0"
                : "0",
            }}
            disabled={status.disabled}
        >
            {status.label}
        </button>
        ))}

        <div className="bookmark-button-container">
        <IconButton
            size="small"
            onClick={handleBookmarkToggle}
            style={{
            padding: "5px",
            }}
        >
            {isBookmarked ? (
            <BookmarkOutlinedIcon className="bookmark-icon" />
            ) : (
            <BookmarkBorderOutlinedIcon className="bookmark-icon" />
            )}
        </IconButton>
        </div>
    </div>
    </div>
);
};

const DateTimePicker = ({
    setStartTimeSelected,
    setEndTimeSelected,
    setErrorsEncountered,
    setStartAndEndTimeClicked,
    submitClicked,
    setSubmitClicked,
    cancelClicked,
    setCancelClicked,
    setNewNotes,
    setTagName,
    setIsBookmarked
}) => {
    
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs(new Date().toISOString()));
    const [startTime, setStartTime] = useState(dayjs(new Date().toISOString()).set("hour", 0).set("minute", 0));
    const [endTime, setEndTime] = useState(dayjs(new Date().toISOString()).set("hour", 0).set("minute", 0));
    const [totaltTimeSpent, setTotalTimeSpent] = useState(dayjs(new Date().toISOString()).set("minute", 0).set("second",0));
    const [errorMsgForTime, setErrorMsgForTime] = useState('');
    const [startTimeClicked, setStartTimeClicked] = useState(false);
    const [endTimeClicked, setEndTimeClicked] = useState(false);

    const [showFlip, setShowFlip] = useState(false);

    useEffect(() => {
        if (showFlip) {
          setShowFlip(true);
          const timer = setTimeout(() => {
            setShowFlip(false);
          }, 3000);
    
          return () => clearTimeout(timer);
        }
      }, [showFlip]);

      useEffect(() => {
        if (submitClicked) {
            resetState();
            setShowFlip(true);
            setSubmitClicked(false);
        }
      }, [submitClicked]);
    
      useEffect(() => {
        if (cancelClicked) {
            resetState();
            setCancelClicked(false);
        }
      }, [cancelClicked]);

    const resetState = () => {
        setSelectedDateTime(dayjs(new Date().toISOString()));
        setStartTime(dayjs(new Date().toISOString()).set("hour", 0).set("minute", 0));
        setEndTime(dayjs(new Date().toISOString()).set("hour", 0).set("minute", 0));
        setTotalTimeSpent(dayjs(new Date().toISOString()).set("minute", 0).set("second",0));
        setErrorMsgForTime('');
        setStartTimeClicked(false);
        setEndTimeClicked(false);
        setNewNotes("");
        setTagName("RPM");
        setIsBookmarked(false);
    }

    const timeValidation = (dateSelection) => {
        setErrorMsgForTime('');
        setErrorsEncountered(false);
        const startDateSelected = dateSelection
                                    .set('hour', startTime.hour())
                                    .set('minute', startTime.minute());
        const endDateSelected = dateSelection
                                    .set('hour', endTime.hour())
                                    .set('minute', endTime.minute());

        const duration = endTime.diff(startTime, 'minute');
        setTotalTimeSpent(dayjs().minute(0).second(0));

        if (!startTime || !endTime) {
            setErrorMsgForTime('Please fill start time or end time!');
            setErrorsEncountered(true);
            return true;
        }
        else if (startDateSelected.isAfter(dayjs()) || endDateSelected.isAfter(dayjs()) ) {
            setErrorMsgForTime('Logging for future time is not allowed!');
            setErrorsEncountered(true);
            return true;
        }
        else if (endTime.isBefore(startTime)) {
            setErrorMsgForTime('End time cannot be earlier than start time!');
            setErrorsEncountered(true);
            return true;
        }
        else if (duration > 59){
            setErrorMsgForTime('Cannot log more than 59 minutes!');
            setErrorsEncountered(true);
            return true;
        }
        else if (duration === 0){
            setErrorMsgForTime('Start time and end time cannot be the same!');
            setErrorsEncountered(true);
            return true;
        }
        setTotalTimeSpent(dayjs().minute(duration).second(0));
        setStartTimeSelected(startDateSelected);
        setEndTimeSelected(endDateSelected);
        return false;
    }
    
    const handleDateTime = (time, fromStartTime) => {
        if (fromStartTime) {
            setStartTimeClicked(true);
            setStartTime(time);
        } else {
            setEndTimeClicked(true);
            setEndTime(time);
        }
    };

    const handleDateChange = (newValue) => {
        if (startTimeClicked && endTimeClicked) {
            const errors = timeValidation(newValue);
            if (errors) {
                return;
            }
        }
        if (newValue) {
            setSelectedDateTime(newValue);
        }
    }

    useEffect(() => {
        if (startTimeClicked && endTimeClicked) {
            timeValidation(selectedDateTime);
            setStartAndEndTimeClicked(true);
        }
    }, [startTime, endTime]);

return (
    <div className="dateTimePickerDiv">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <HtmlTooltip title="Select Date" placement="top" arrow>
                <div>
                    <DatePicker
                    className="date-selector"
                        value={selectedDateTime}
                        onChange={(newValue) => { handleDateChange(newValue) }}
                        maxDate={dayjs(new Date())}
                    />
                </div>
            </HtmlTooltip>

            <HtmlTooltip 
                title={errorMsgForTime || ""} 
                placement="bottom"
                open={!!errorMsgForTime}
                PopperProps={{
                    container: document.querySelector('#scrollable-container')
                  }}
                customStyles={{
                    marginLeft: "5px",
                    background: "#aa346f",
                }}
                >
            <div className={`time-pill-container ${showFlip ? "flip" : ""}`}>
                <div className="time-pill flip-card-front">
                    <AccessTime style={{
                            color: startTimeClicked && endTimeClicked ? "#AA346F" : "rgb(174, 155, 164)"
                        }}/>
                    <HtmlTooltip title="Session Start Time" placement="top" arrow>
                        <div className="startTimePickerDiv" style={{
                                color: startTimeClicked ? "#AA346F" : "rgb(174, 155, 164)"
                            }}>
                            <TimePicker
                                value={startTime}
                                className="timePicker startTime"
                                onChange={(newValue) => handleDateTime(newValue, true)}
                                disableOpenPicker={true}
                            />
                        </div>
                    </HtmlTooltip>
                    <East style={{
                            color: startTimeClicked ? "#AA346F" : "rgb(174, 155, 164)"
                        }}/>
                    <HtmlTooltip title="Session End Time" placement="top" arrow>
                        <div className="endTimePickerDiv" style={{
                                color: endTimeClicked ? "#AA346F" : "rgb(174, 155, 164)"
                            }}>
                            <TimePicker
                                value={endTime}
                                className="timePicker startTime"
                                onChange={(newValue) => handleDateTime(newValue, false)}
                                disableOpenPicker={true}
                            />
                        </div>
                    </HtmlTooltip>
                    <AccessTime style={{
                            color: startTimeClicked && endTimeClicked ? "#AA346F" : "rgb(174, 155, 164)"
                        }}/>
                </div>
                <div className="time-pill flip-card-back">
                    <p>Timer log has been successfully created!</p>
                </div>
            </div>
            </HtmlTooltip>

            <HtmlTooltip title="Total Time Spent" placement="top" arrow>
                <div className="right-pill">
                    <AccessTime style={{
                            color: totaltTimeSpent.minute() > 0 ? "#AA346F" : "rgb(174, 155, 164)"
                        }}/>
                    <div className="startTimePickerDiv" style={{
                            color: totaltTimeSpent.minute() > 0 ? "#AA346F" : "rgb(174, 155, 164)"
                        }}>
                    <TimePicker
                        value={totaltTimeSpent}
                        className="timePicker startTime"
                        views={["minutes"]}
                        format="mm"
                        disableOpenPicker={true}
                    /> 
                    </div>
                    <span style={{
                            color: totaltTimeSpent.minute() > 0 ? "#AA346F" : "rgb(174, 155, 164)"
                        }}>mins</span>
                </div>
            </HtmlTooltip>
        </LocalizationProvider>
    </div>
);
};

const LogTime = ({
notesIcon,
newNotes,
setNewNotes,
tagName,
setTagName,
isBookmarked,
setIsBookmarked,
onSubmit,
expandDefault,
onToggle,
disabled,
setStartTimeSelected,
setEndTimeSelected
}) => {

const [errorsEncountered, setErrorsEncountered] = useState(false);
const [startAndEndTimeClicked, setStartAndEndTimeClicked] = useState(false);
const [submitClicked, setSubmitClicked] = useState(false);
const [cancelClicked, setCancelClicked] = useState(false);

const submitLog = () => {
    setSubmitClicked(true);
    onSubmit();
}

return (
    <Accordion expanded={expandDefault} onChange={onToggle} disabled={disabled} className="custom-accordion">
    <AccordionSummary
        expandIcon={<ExpandCircleDown style={{
        color: expandDefault ? "#AA346F" : "#D491B3"
        }} />}
        className="accordion-summary"
        style={{
        backgroundColor: expandDefault ? "#EFC5D9" : "#F3E8EE"
        }}
    >
        <div className="icon-container">
        {notesIcon}
        </div>
        <div className="accordion-title">
        {"Log Time"}
        </div>
    </AccordionSummary>
    <AccordionDetails className="accordion-details"  id="scrollable-container">
        <DateTimePicker
            setStartTimeSelected={setStartTimeSelected}
            setEndTimeSelected={setEndTimeSelected}
            setErrorsEncountered={setErrorsEncountered}
            setStartAndEndTimeClicked={setStartAndEndTimeClicked}
            submitClicked={submitClicked}
            setSubmitClicked={setSubmitClicked}
            cancelClicked={cancelClicked}
            setCancelClicked={setCancelClicked}
            setNewNotes={setNewNotes}
            setTagName={setTagName}
            setIsBookmarked={setIsBookmarked}
        />
        
        <TextBoxWithButtons
        newNotes={newNotes}
        setNewNotes={setNewNotes}
        tagName={tagName}
        setTagName={setTagName}
        statusOptions={statusOptions}
        isBookmarked={isBookmarked}
        setIsBookmarked={setIsBookmarked}
        />

        <div className="action-buttons-container">
        <button onClick={()=>{setCancelClicked(true)}} 
            className="cancel-button">
            Cancel
        </button>

        <HtmlTooltip
            title={
            !tagName || !newNotes
                ? "Please enter a note and select a tag to submit."
                : newNotes.trim() === "" ? "Notes cannot be empty."
                : errorsEncountered ? "Please resolve date time errors" 
                : !startAndEndTimeClicked ? "Please fill the time for log" : ""
            }
            placement="top"
            customStyles= {{
            maxWidth: "300px !important"
            }}
        >
            <span className="submit-button-wrapper">
            <button
                onClick={() => submitLog()}
                className="submit-button"
                disabled={!tagName || !newNotes || newNotes.trim() === "" || errorsEncountered || !startAndEndTimeClicked}
            >
                {"Submit"}
            </button>
            </span>
            </HtmlTooltip>
        </div>
    </AccordionDetails>
    </Accordion>
);
};


export default LogTime;
