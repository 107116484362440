import React, { useState } from "react";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./AddNotes.css";
import HtmlTooltip from "../../../../Utils/HtmlTooltip";
import { ExpandCircleDown } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const statusOptions = [
    { label: "CCM", color: "#7C596A", disabled: true },
    { label: "RPM", color: "#AA346F" },
    { label: "GENERAL", color: "#E86EAB" },
  ];
dayjs.extend(utc);
dayjs.extend(timezone);
const timeZoneFromDayjs = dayjs.tz.guess();

      const TextBoxWithButtons = ({
        newNotes,
        setNewNotes,
        tagName,
        setTagName,
        isBookmarked,
        setIsBookmarked,
        selectedRecordTS,
      }) => {
        const handleBookmarkToggle = () => {
            setIsBookmarked((prev) => !prev);
        };
      
        const handleStatusSelect = (status) => {
            if (tagName === status) {
              setTagName("");
            } else {
              setTagName(status);
            }
        };
      
        return (
          <div className="textbox-container">
            <div className="textarea-wrapper">
              <textarea
                value={newNotes}
                onChange={(e) => setNewNotes(e.target.value)}
                placeholder={"Describe any relevant information about the patient. E.g., I use Omron blood pressure cuff biweekly."}
                maxLength={5000}
                multiline='true'
                disableunderline="true"
                className="note-textarea"
              />
              {selectedRecordTS && <div className="createdDateTime fromNotesTime">
                                {dayjs.utc(selectedRecordTS).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                            </div>}
              <div className="character-count">
                {newNotes.length} / 5000 Characters
              </div>
            </div>
      
            <div className="status-buttons-container">
              {statusOptions.map((status, index) => (
                <button
                  key={status.label}
                  onClick={() => handleStatusSelect(status.label)}
                  className={`status-button ${
                    tagName === status.label ? "selected" : ""
                  } ${
                    status.disabled ? "disabledTag" : ""
                    }`}
                  style={{
                    borderColor: tagName === status.label ? status.color : "#ddd",
                    backgroundColor:
                      tagName === status.label ? status.color : "#F6EFF4",
                    color: tagName === status.label ? "#FFFFFF" : status.color,
                    borderRadius:
                      index === 0
                        ? "0 0 0 8px"
                        : index === statusOptions.length - 1
                        ? "0"
                        : "0",
                    fontFamily: "GTWalsheimProRegular",
                  }}
                  disabled={status.disabled}
                >
                  {status.label}
                </button>
              ))}
      
              <div className="bookmark-button-container">
                <IconButton
                  size="small"
                  onClick={handleBookmarkToggle}
                  style={{
                    padding: "5px",
                  }}
                >
                  {isBookmarked ? (
                    <BookmarkOutlinedIcon className="bookmark-icon" />
                  ) : (
                    <BookmarkBorderOutlinedIcon className="bookmark-icon" />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
        );
      };

    const AddNotes = ({
        notesIcon,
        newNotes,
        setNewNotes,
        tagName,
        setTagName,
        isBookmarked,
        setIsBookmarked,
        onSubmit,
        onCancel,
        expandDefault,
        onToggle,
        selectedRecordTS
      }) => {
        const handleSubmit = () => {
          if (!tagName) {
            alert("Please select a status to submit.");
            return;
          }
      
          if (newNotes.trim() === "") {
            alert("Note cannot be empty.");
            return;
          }
      
          onSubmit();
        };
      
        return (
          <Accordion expanded={expandDefault} onChange={onToggle} className="custom-accordion">
            <AccordionSummary
              expandIcon={<ExpandCircleDown style={{
                color: expandDefault ? "#AA346F" : "#D491B3"
              }} />}
              className="accordion-summary"
              style={{
                backgroundColor: expandDefault ? "#EFC5D9" : "#F3E8EE"
              }}
            >
              <div className="icon-container">
                {notesIcon}
              </div>
              <div className="accordion-title">
                Add
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <TextBoxWithButtons
                newNotes={newNotes}
                setNewNotes={setNewNotes}
                tagName={tagName}
                setTagName={setTagName}
                statusOptions={statusOptions}
                isBookmarked={isBookmarked}
                setIsBookmarked={setIsBookmarked}
                selectedRecordTS={selectedRecordTS}
              />
      
              <div className="action-buttons-container">
                <button onClick={onCancel} className="cancel-button">
                  Cancel
                </button>
      
                <HtmlTooltip
                  title={
                    !tagName || !newNotes
                      ? "Please enter a note and select a tag to submit."
                      : newNotes.trim() === "" ? "Notes cannot be empty."
                      : ""
                  }
                  placement="top"
                  customStyles= {{
                    maxWidth: "300px !important"
                  }}
                >
                  <span className="submit-button-wrapper">
                    <button
                      onClick={handleSubmit}
                      className="submit-button"
                      disabled={!tagName || !newNotes || newNotes.trim() === ""}
                    >
                      {"Submit"}
                    </button>
                  </span>
                  </HtmlTooltip>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      };


export default AddNotes;
